let TextStrings = {
	general: {
		confirm: 'Bestätigen',
		ok: 'OK',
		cancel: 'Abbrechen',
		back: 'Zurück',
		change: 'Ändern',
		yes: 'Ja',
		no: 'Nein',
		skip: 'Überspringen',
		continue: 'Weiter',
		loading: 'Ein Augenblick bitte...',
		notLoggedIn: 'Du bist nicht eingeloggt.',
	},
	nav: {
		bookkeeping: 'Rechnungen',
		search: 'Suche',
		dashboard: 'Dashboard',
		orders: 'Aufträge',
		newUser: 'Neuer Kunde',
		fullScreen: 'Vollbild',
		help: 'Hilfe',
		logOut: 'Ausloggen',
	},
	help: {
		prompt: 'Etwas funktioniert nicht!',
		telephone: 'Bei Fragen zu Lieferungen ruf bitte 079 808 96 92 an.',
		email: 'Oder schick uns eine Nachricht an ',
		emailAddress: 'support@rikscha.ch',
		lede: 'Dieses Fenster kann minimiert werden.',
		placeholder: 'Oder schreib deine Nachricht hier...',
		send: 'Einsenden',
		received: 'Wir haben deine Nachricht erhalten. Merci!',
	},
	error: {
		title: 'Hoppla!',
		message: 'Etwas ist schief gelaufen. Nochmal versuchen?',
	},
	auth: {
		title: 'Administrative Interface',
		email: 'Email',
		password: 'Passwort',
		login: 'Einloggen',
	},
	orders: {
		title: 'Aufträge',
		noOrders: 'Es gibt hier noch keine Aufträge.',
	},
	order: {
		newOrder: 'Neuer Auftrag',
		order: 'Auftrag #',
		deliveryStatus:'Lieferung Status',
		paymentStatus: 'Zahlungsstatus',
		deliveryStatuses: {
			received: 'Bestellt',
			created: 'Erstellt',
			dispatched: 'Versandt',
			picked_up: 'Abgeholt',
			pickup_failed: 'Abholung Gescheitert',
			delivered: 'Zugestellt',
			delivery_failed: 'Zugestellt Gescheitert',
			returned: 'Zurückgeschickt',
			cancelled: 'In Bearbeitung',
		},
		paymentStatuses: {
			incomplete: 'Unvollständig',
			processing: 'Verarbeitung',
			succeeded: 'Erfolgreich',
			amount_capturable_updated: 'Erfassbarer Betrag aktualisiert',
			payment_failed: 'Zahlung fehlgeschlagen',
		},

		addressPickup: 'Abholadresse',
		addressDelivery: 'Lieferadresse',
		item: 'Artikel',
		price: 'Preis',
		total: 'Total',
		noOrderItems1: 'Keine Artikel.',
		noOrderItems2: 'Bitte scannen Sie einen DingDong QR-Code.',

		steps: {
			scanQRCode: 'QR-Code Scannen',
			takePicture: 'Bild Aufnehmen',
			chooseSubscription: 'Abonnement Auswählen',
			enterCode: 'Code Eingeben',
			checkOut: 'Auschecken',
		},

		complete: 'Auftrag erhalten!',
		viewTheOrder: 'Tippe hier, um die Bestellung anzuzeigen',

		administrativeCharge: 'Verwaltungsgebühr',
	},
	search: {
		title: 'Suche',
		titleAction: 'Suche: ',

		searching: 'Auf der Suche nach ',

		noResults: 'Keine Resultate gefunden.',
		newUser: 'Neuen Benutzer hinzufügen',
	},
	scanner: {

	},
	subscription: {
		subscription: 'Abo',
		subscriptions: 'Abos',
		yearly: {
			period: 'Jährlich',
			adverb: 'Jährliches',
			singleton: 'Jahr',
		},
		monthly: {
			period: 'Monatlich',
			adverb: 'Monatliches',
			singleton: 'Monat',
		},
		automatic: '(automatisch berechnet)',
		noSubscription: 'Kein Abonnement gewünscht',
	},
	code: {
		title: 'Rabattcode eingeben',
		prompt: 'Wenn der Kunde einen Rabattcode angegeben hat, gib ihn hier ein. Sonst lass das Feld leer.',
		tryCode: 'Code Versuchen',
		codeSuccess: 'Der Code ist gültig! Der Rabatt wurde auf den Gesamtbetrag angewendet.',
		codeFailure: 'Der Code ist ungültig.',
	},
	checkout: {
		head: 'Alles korrekt?',
		lede: 'Bevor du den Auftrag abschickst, bitte:',
		step_1: 'überprüfe alle Eingaben – sind alle Artikel gescannt worden?',
		step_2: ['dem Kunden ', ' berechnen'],
		somethingMissing: 'Es fehlt etwas? Gehe zurück und ändere die Reihenfolge mit den Schaltflächen oben.',
		send: 'Schon erledigt – Auftrag senden',
	},
	user: {
		newUser: 'Neue Benutzer',
		existingUser: 'Benutzer: ',
		email: 'Email',
		firstName: 'Vorname *',
		lastName: 'Nachname *',
		companyName: 'Unternehmen',
		mobile: 'Handynummer',
		line1: 'Adresse *',
		line2: 'Adresse',
		city: 'Stadt *',
		zip: 'PLZ *',
		floor: 'Stockwerk *',
		hasLift: 'Gibt\'s einen Aufzug? *',
		hasParking: 'Abstellgenehmigung? *',
		deliveryInstructions: 'Andere Lieferanweisungen',

		orders: 'Aufträge',
		newOrder: 'Neuen Auftrag erfassen',
		save: 'Benutzerdetails speichern',
		incompleteOrder: 'Unvollständige Aufträge',

		noSubscription: 'Kein Abonnement',
		outOfServiceAreaError: 'Die eingegebene Postleitzahl liegt leider nicht im Servicegebiet von Dingdong.',
	},
	dashboard: {
		title: 'Dashboard',
		byMonth: 'Aufträge nach Monat',
		delivered: 'Geliefert / Nicht geliefert',
		inTotal: 'Aufträge insgesamt',
		revenue: 'Bisherige Einnahmen',
		byHour: 'Aufträge nach Uhrzeit',
		byWeekday: 'Aufträge nach Wochentag',
		days: [
			'So',
			'Mo',
			'Di',
			'Mi',
			'Do',
			'Fr',
			'Sa',
		],
	},
	bookKeeping: {
		title: 'Buchhaltung',
		doBookKeeping: 'Abrechnen',

		orders: 'Aufträge',
		ordersFilename: 'Auftraege',
		subscriptions: 'Abos',

		date: 'Datum',
		name: 'Name',
		articleCount: '# Artikeln',
		orderTotal: 'Summe',

		period: 'Zeitspanne',
		discount: 'Rabatt?',
		noCode: '–',

		ordersCSVGenerate:'CSV-Datei für deise Aufträge generieren',
		subsCSVGenerate:'CSV-Datei für deise Abos generieren',

		noOrders: 'Es gibt keine Aufträge.',
		noSubs: 'Es gibt keine Abonnements.',

		fileDownload: ' herunterladen',
	},
	
}

export default TextStrings;