import { deepFreeze } from '../functions/util';

let products = {};

export function setProducts(theProducts) {
	products = theProducts;
	deepFreeze(products)
}

export function getProducts() {
	console.log('HEY HERE ARE THE PRODUCTS', products)
	return products;
}

