import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from "react-router-dom";

import Nav from '../../&c./nav/nav';
import Loader from '../../&c./loader/loader'

import { api_getOrders, api_getServiceArea } from '../../../functions/api';
import { navigate } from '../../../functions/navigator';

import { setOrders } from '../../../context/OrdersContext';
import { areWeLoggedIn, theCurrentUserIsA } from '../../../context/LoggedInContext'
import { remoteConfigSetup } from '../../../context/RemoteConfigContext'
import { setRole } from '../../../context/RoleContext';

import TextStrings from '../../../constants/TextStrings'

export default function Root() {
  let _nav = useNavigate();
  let [loggedIn, setLoggedIn] = useState(false);
  let [isLoading, setIsLoading] = useState(true);
  let [userRole, setUserRole] = useState();

  let hello = async () => {
    setIsLoading(true);

    let loggedInState = await areWeLoggedIn();

    console.log(window.location)

    console.log('loggedInState', loggedInState)


    if (!loggedInState) {
      navigate(_nav, '/')
    } else {
      remoteConfigSetup();
      setLoggedIn(loggedInState);
      
      await api_getServiceArea();

      await api_getOrders();

      let _userRole = await theCurrentUserIsA();

      setUserRole(_userRole)
      setRole(_userRole)

      console.log('USER IS A ', _userRole)
      if (_userRole === 'master') {
        navigate(_nav, '/home/bookkeeping');
      } else if (_userRole === 'admin') {
        navigate(_nav, '/home/dashboard');
      } else if (_userRole === 'operator') {
        navigate(_nav, '/home/orders');
      }
    }

    setIsLoading(false);
  }

  useEffect(() => {
    hello();
    console.log('ROOOOT')

    const ordersInterval = setInterval(api_getOrders, 300000);
    return () => clearInterval(ordersInterval);
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
         !loggedIn ? (
          <p>{ TextStrings.general.notLoggedIn }</p>
        ) : (
        <div className="master">
          <Nav role={ userRole } />

          <div className="main">
            <Outlet />

          </div>
        </div>
        )
      )}
      
    </>
  );
}