let storedSearchResults = [];
let storedSearchQuery = '';
let holding = false;

export function storeSearchResults(query, results) {
	storedSearchQuery = query;
	storedSearchResults = results;
	holding = true;
}

export function retreiveSearchResults() {
	if (storedSearchResults.length && holding) {
		holding = false
		return {
			query: storedSearchQuery,
			results: storedSearchResults
		};
	} else return false;
}

export function holdThatThought() {
	holding = true;
}