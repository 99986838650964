import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import PinDropIcon from '@mui/icons-material/PinDrop';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import TuneIcon from '@mui/icons-material/Tune';
import ImageIcon from '@mui/icons-material/Image';

import QrCodeIcon from '@mui/icons-material/QrCode';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import Touchable from '../../&c./touchable/touchable';
import OrderItems from './_orderItems/orderItems';
import Lens from './lens/lens';
import Code from './code/code';
import Subscription from './subscription/subscription';
import Checkout from './checkout/checkout';

import TextStrings from '../../../constants/TextStrings'

import Head from '../../&c./head/head';
import Select from '../../&c./select/select';

import { 
	isEmptyObject, 
  displayStripeAmount, 
  makeRandomHash,
  isNumber,
  convertTimestampToHumanReadable_hourMinute
} from '../../../functions/util';
import { 
	api_promoCodeInput, 
  api_orderDelivery,
  api_orderSubscription,
  api_uploadImage,
  api_getOrders,
  api_updateDeliveryStatus
} from '../../../functions/api';
import cache from '../../../functions/cache';
import { setTitle } from '../../../functions/title';
import { navigate } from '../../../functions/navigator';

import { getOrder } from '../../../context/OrdersContext';
import { saveOrder, retreiveOrder } from '../../../context/OrderContext'
import { getUser } from '../../../context/UserContext';
import { getProducts } from '../../../context/ProductsContext';
import { getLocation } from '../../../context/LocationContext';
import { getRole } from '../../../context/RoleContext';

export default function Order() {
  let _nav = useNavigate();
	let [isLoading, setIsLoading] = useState(true);
	let { orderId } = useParams();
	let location = useLocation();

	let [order, setOrder] = useState({})
	let [user, setUser] = useState({})
	let [userAddress, setUserAddress] = useState({})

	let [newOrder, setNewOrder] = useState(false);
	let [orderMode, setOrderMode] = useState('scan');
	let [orderSequence, setOrderSequence] = useState([])
	let [orderNavIcons, setOrderNavIcons] = useState([])
	let [userWillBeSubscribed, setUserWillBeSubscribed] = useState(false);
	let [userIsAlreadySubscribed, setUserIsAlreadySubscribed] = useState(false)

	let [products, setProducts] = useState([]);
	let [orderDisplay, setOrderDisplay] = useState({});

	let [carouselAt, setCarouselAt] = useState(0)

	let [userRole, setUserRole] = useState()

	let orderSequenceSetup = (_isSubscribed) => {
		let theSequence;
		let theSequenceIcons;
		if (!_isSubscribed) {
			theSequence = [
				'scan', 'camera', 'abo', 'code', 'checkout'
			];
			theSequenceIcons = [
				<QrCodeIcon />,
				<CameraAltIcon />,
				<SubscriptionsIcon />,
				<LocalOfferIcon />,
				<ShoppingCartIcon />
			]
		} else {
			theSequence = [
				'scan', 'camera', 'checkout'
			];

			theSequenceIcons = [
				<QrCodeIcon />,
				<CameraAltIcon />,
				<ShoppingCartIcon />
			]
		}
		setOrderSequence(theSequence)
		setOrderNavIcons(theSequenceIcons)
	}

	let hello = async () => {
		if (orderId === 'new') {
			setTitle(TextStrings.order.newOrder);
			let theUser = await getUser();
			console.log('theUser', theUser)
			setUser(theUser);
			setUserAddress(theUser.address);
			console.log(orderMode)

			let orderPlacedBy = {
				operatorEmail: cache.operatorEmail.retreive(),
				operatorPassword: cache.operatorPassword.retreive(),
				operatorUID: cache.operatorUID.retreive(),
				locationId: cache.locationId.retreive()
			}

			let theProducts = await getProducts();
			setProducts(theProducts);

			let theLocation = await getLocation();

			setOrder({
				deliveryId: makeRandomHash().toUpperCase(),
				subscription: {},
				deliveryItems: [],
				collectLocation: theLocation,
				deliverLocation: theUser.address,
				coupon: {},
				userId: theUser.uid,
				orderPlacedBy
			})

			let _isSubscribed = theUser.subscribed || !isEmptyObject(theUser.subscription)
			setUserIsAlreadySubscribed(_isSubscribed)
			orderSequenceSetup(_isSubscribed)

			setNewOrder(true);

		} else if (orderId === 'return') {

			let theUser = await getUser();
			setUser(theUser);
			setUserAddress(theUser.address);

			let theProducts = await getProducts();
			setProducts(theProducts);

			let theOrder = retreiveOrder(theUser.uid)

			let _isSubscribed = theUser.subscribed || !isEmptyObject(theUser.subscription)
			setUserIsAlreadySubscribed(_isSubscribed)
			orderSequenceSetup(_isSubscribed)

			setTitle(TextStrings.order.newOrder);

			console.log('theOrder', theOrder)
			setOrder(theOrder)

		} else {
			setOrderMode('display')

			let theOrder = await getOrder(orderId);
			setTitle(TextStrings.order.order + theOrder.id);
			console.log('theOrder', theOrder)
			setOrder(theOrder);
			setUser({
				uid: theOrder.user
			})
			setUserAddress(theOrder.deliverLocation);
			setProducts(theOrder.productSnapshot);
		}

		let _userRole = getRole();

		setUserRole(_userRole)

		setIsLoading(false);
	}

	let killStreams = async () => {
		let stream = await navigator.mediaDevices.getUserMedia({video: true})
		stream.getTracks().forEach((track) => {
			track.stop();
		})
	}

	useEffect(() => {
		hello()

		return () => {
			killStreams()
		}
	}, [])

	useEffect(() => {
		console.log('ORDER ID', orderId)
		hello()
	}, [orderId])

	let editUser = () => {
		saveOrder(order);
		navigate(_nav, `/home/user/${user.uid}`)
	}

	let orderForward = () => {
		let currentIndex = orderSequence.indexOf(orderMode);
		let forward = orderSequence[++currentIndex]
		setOrderMode(forward)		
	}


	let orderReverse = () => {		
		let currentIndex = orderSequence.indexOf(orderMode);
		let backwards = orderSequence[--currentIndex]
		setOrderMode(backwards)		
	}

	let packageScanned = (code) => {
		let orderCopy = {...order};
		console.log('PCKG SCANNED', code, orderCopy.deliveryItems.indexOf(code));
		console.log('order', order)
		if (orderCopy.deliveryItems.indexOf(code) < 0) {
			orderCopy.deliveryItems.push(code);
			setOrder(orderCopy);			
		}
	}

	let removePackage = (which) => {
		console.log('REMOVe', which);
		let orderCopy = {...order};
		let removeThisOne = orderCopy.deliveryItems.indexOf(which)
		orderCopy.deliveryItems.splice(removeThisOne, 1);
		setOrder(orderCopy);
		console.log()
	}


	let pictureTaken = async (data) => {
		console.log('order.deliveryId', order.deliveryId, order, 'data', data)

		let pictureUpload = await api_uploadImage(order.deliveryId, data)

		orderForward();
	}


	let subscriptionChosen = (subscription) => {
		// setDesiredSubscription(subscription)
		let orderCopy = {...order};

		if (!subscription.hasOwnProperty('nothing')) {
			Object.assign(orderCopy, { subscription });
			setUserWillBeSubscribed(false)
		} else {
			Object.assign(orderCopy, { subscription: {} });			
			setUserWillBeSubscribed(true)
		}

		// if (orderCopy.hasOwnProperty(coupon)) {
		// 	delete orderCopy.coupon;
		// }

		console.log(orderCopy)
		setOrder(orderCopy);
	}

	let addCode = (coupon) => {
		let orderCopy = {...order};

		Object.assign(orderCopy, { coupon });

		setOrder(orderCopy);
	}

	let updateDisplay = (theOrderDisplay) => {
		setOrderDisplay(theOrderDisplay)
	}

	let placeOrder = async () => {
		if (!order.subscription.hasOwnProperty('nothing')) {
			let subscriptionSuccess = await api_orderSubscription(order);
		}

		let orderSuccess = await api_orderDelivery(order);

		if (orderSuccess.orderPlaced) {
			await api_getOrders();
			setOrderMode('complete')
		}
		console.log(orderSuccess)
	}

	let viewJustPlacedOrder = async () => {
		navigate(_nav, `/home/order/${order.deliveryId}`)
	}

	let statusChanged = async (newStatus) => {
		let updateStatus = await api_updateDeliveryStatus(order.id, newStatus)

		Object.assign(order, {
			deliveryStatus: newStatus
		})
		console.log('STATUS UPDATE', newStatus, order)
	}


	return (
		<>
			<div className="order">
				{ !isLoading && (
					<>
						<div className={ `orderCarousel carousel_${carouselAt}` }>
							<Head 
								monospace={ orderMode === 'display' }
								_nav={ _nav }
								title={ orderMode === 'display' ? order.id : TextStrings.order.newOrder } 
								subtitle={ order.hasOwnProperty('dispatchId') ? ` / ${order.dispatchId}` : '' } />
							<div className="orderCarouselSelect">
								<div className="orderCarouselSelector" onClick={() => setCarouselAt(0)}>
									<PinDropIcon/>
								</div>
								<div className="orderCarouselSelector" onClick={() => setCarouselAt(1)}>
									<ShoppingBasketIcon/>
								</div>
								<div className="orderCarouselSelector" onClick={() => setCarouselAt(2)}>
								{ orderMode === 'display' ? (
									<ImageIcon/>									
								) : (
									<TuneIcon/>
								)}
								</div>
							</div>
							<div className="orderCarouselInterior">
								
								<div className="orderDisplay">

									{ orderMode === 'display' && (
										<div className="orderDisplaySection orderHead">
											<h4>{ convertTimestampToHumanReadable_hourMinute(order.orderTimestamp) }</h4>

											<div className="orderInfo">
												<h3>{ TextStrings.order.deliveryStatus }</h3>
												{userRole === 'master' ? (
													<Select 
														options={ TextStrings.order.deliveryStatuses }
														selected={ order.deliveryStatus }
														onSelect={ statusChanged }
													/>
												) : (
													<p>{ TextStrings.order.deliveryStatuses[order.deliveryStatus] }</p>
												)}
											</div>
											<div className="orderInfo">
												<h3>{ TextStrings.order.paymentStatus }</h3>
												<p>{ TextStrings.order.paymentStatuses[order.paymentStatus] }</p>
											</div>
										</div>
									)}
									<div className="orderDisplaySection orderAddresses">							
										<div className="orderInfo orderAddressCollect">
											<h3>{ TextStrings.order.addressPickup }</h3>
											<p>
												{ `${order.collectLocation.companyName}` } <br />
												{ `${order.collectLocation.line1}` } <br />
												{ `${order.collectLocation.zip} ` } 
												{ `${order.collectLocation.city}` } <br />
											</p>
										</div>
										<div className="orderInfo orderAddressDeliver">
											<h3>{ TextStrings.order.addressDelivery }</h3>
											<p>
												{ (userAddress.firstName && userAddress.lastName) && (
													<>{userAddress.firstName} {userAddress.lastName} <br /></>
												)}
												{ userAddress.mobile && (
													<>{userAddress.mobile}<br /></>
												)}
												{ userAddress.line1 && (
													<>{userAddress.line1} <br /></>
												)}
												{ userAddress.line2 && (
													<>{userAddress.line2} <br /></>
												)}
												{ userAddress.zip && (
													<>{userAddress.zip} </>
												)}
												{ userAddress.city && (
													<>{userAddress.city} <br /></>
												)}
												{ userAddress.deliveryInstructions && (
													<>{userAddress.deliveryInstructions} </>
												)}
											</p>

										</div>
									</div>

								</div>

								<div className="orderItemsColumn">
									<OrderItems
										order={ order }
										subscribed={ userWillBeSubscribed || userIsAlreadySubscribed } 
										products={ products }
										isDisplay={ orderMode === 'display' }
										display={ updateDisplay }
										remove={ removePackage }
									/>

								</div>

								<div className="orderCompose">
									{ orderMode === 'display' && (
										<div 
											className="orderImage" 
											style={{backgroundImage: `url(${ order.imageURL })`}} />
									)} 

									{ orderMode === 'complete' && (
										<>
											<h1 className="orderComplete">{ TextStrings.order.complete }</h1>

											<p className="viewOrder" onClick={ viewJustPlacedOrder }><span>{ TextStrings.order.viewTheOrder }</span></p>
										</>
									)}

									{ (orderMode !== 'display' && orderMode !== 'complete') && (
										<>
											<div className={`orderStepDisplay orderStep_${orderMode}`}>
												{ orderSequence.map((step, index) => {

													return (
														<div 
															onClick={ () => setOrderMode(step) } 
															className={`orderStep ${orderMode === step ? 'current' : ''} ${orderSequence.indexOf(orderMode) > index ? 'complete' : ''}`}>
															<div className="orderStepInterior">
															  { orderNavIcons[index] }
															</div>
														</div>
													)
												})}
											</div>

											<div className="orderComposeFrame">
													
												{ ((orderMode === 'scan') || (orderMode === 'camera')) && (
													<Lens 
														scanner={ orderMode === 'scan' }
														camera={ orderMode === 'camera' }
														image={ pictureTaken }
														code={ packageScanned }
													/>
												)}
												{ orderMode === 'abo' && (
													<Subscription 
														order={ order }
														products={ products } 
														onChoose={ subscriptionChosen } />
												)}
												{ orderMode === 'code' && (
													<Code codeEntry={ addCode } />
												)}
												{ orderMode === 'checkout' && (
													<Checkout 
														display={ orderDisplay }
														canConfirm={ !!order.deliveryItems.length }
														confirm={ placeOrder }
													/>
												)}

											</div>

											{ (
													(orderMode === 'scan') || 
													(orderMode === 'abo') || 
													(orderMode === 'code')
												) && (
												<div className="orderComposeControl">
													<Touchable 
														onPress={ orderForward } >
														<p>{ TextStrings.general.continue }</p>
													</Touchable>
												</div>
											)}
										</>
									)}
								</div>
							</div>							
						</div>
					</>
				)}
			</div>
		</>
	)
}