import { initializeApp, getApp } from "firebase/app";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getStorage, ref, uploadString } from "firebase/storage";

import cache from './cache';

import { setLoggedInStateTo, weAreLoggedInAsA } from '../context/LoggedInContext'
import { getUser, setUser, setOnUser } from '../context/UserContext';
import { setOrders } from '../context/OrdersContext';
import { setLocation } from '../context/LocationContext';
import { setDisplay } from '../context/DisplayContext';
import { setServiceArea } from '../context/ServiceAreaContext'

const firebaseConfig = {
  apiKey: "AIzaSyC7H-1yKDjqSEhyFzUi93AP7YCgx8EIpsw",
  authDomain: "dingdong-2e2db.firebaseapp.com",
  databaseURL: "https://dingdong-2e2db-default-rtdb.europe-west1.firebasedatabase.app",

  projectId: "dingdong-2e2db",
  storageBucket: "dingdong-2e2db.appspot.com",
  messagingSenderId: "528990417821",
  appId: "1:528990417821:web:c80304c3b1c5e36a733574",
  measurementId: "G-22KZRTQMJS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const storage = getStorage(app);

if (import.meta.env.VITE_ENVIRONMENT === 'dev') {
	connectFunctionsEmulator(functions, "192.168.0.170", 5001);
}


function apiLogger(domain, desire, body, response) {
	console.log(' ')
	console.log('////////////////////////////////////////////////////////////////')
	console.log('DOMAIN', domain)
	console.log('DESIRE', desire)
	if (!!body) {
		console.log('BODY', body)
	}
	if (!!response) {
		console.log('RESPONSE', JSON.stringify(response))
	}
	console.log('++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++')
	console.log(' ')
}

// 
//        db                           88
//       d88b                    ,d    88
//      d8'`8b                   88    88
//     d8'  `8b    88       88 MM88MMM 88,dPPYba,
//    d8YaaaaY8b   88       88   88    88P'    "8a
//   d8""""""""8b  88       88   88    88       88
//  d8'        `8b "8a,   ,a88   88,   88       88
// d8'          `8b `"YbbdP'Y8   "Y888 88       88
// 


export async function api_login(email, password) {
	return new Promise(async (resolve, reject) => {
		const auth = getAuth();
		signInWithEmailAndPassword(auth, email, password)
		  .then(async (userCredential) => {

		  	apiLogger('auth', 'login', {email, password: '****'}, userCredential)

		    const user = userCredential.user;

		    cache.operatorEmail.store(email)
		    cache.operatorPassword.store(password)
		    cache.operatorUID.store(userCredential.user.uid)

		    setLoggedInStateTo(true);

		    resolve(true)
		  })
		  .catch((error) => {
		    const errorCode = error.code;
		    const errorMessage = error.message;
		  });
	})
}


export async function api_logout(email, password) {
	return new Promise(async (resolve, reject) => {
		const auth = getAuth();
		signOut(auth)
		  .then(async () => {

		  	apiLogger('auth', 'logout', false, false)

		    cache.operatorEmail.remove()
		    cache.operatorPassword.remove()
		    cache.operatorUID.remove()
		    cache.locationId.remove()

		    setLoggedInStateTo(false);

		    resolve(true)
		  })
		  .catch((error) => {
		    const errorCode = error.code;
		    const errorMessage = error.message;
		  });
	})
}



// 
// 88        88
// 88        88
// 88        88
// 88        88 ,adPPYba,  ,adPPYba, 8b,dPPYba,
// 88        88 I8[    "" a8P_____88 88P'   "Y8
// 88        88  `"Y8ba,  8PP""""""" 88
// Y8a.    .a8P aa    ]8I "8b,   ,aa 88
//  `"Y8888Y"'  `"YbbdP"'  `"Ybbd8"' 88
// 



export async function api_searchUser(query) {
	return new Promise(async (resolve, reject) => {
		const searchUser = httpsCallable(functions, 'searchUser');

		searchUser(query)
		  .then((result) => {
				apiLogger('edge', 'search', query, result);
		    resolve(result.data)
		  });
	})
}


export async function api_getUser(id) {
	return new Promise(async (resolve, reject) => {		

		const getUser = httpsCallable(functions, 'getUser');

		getUser(id)
		  .then((result) => {
				apiLogger('edge', 'get user', id, result);
		    resolve(result.data)
		  });
	})
}


export async function api_updateUser(user) {
	return new Promise(async (resolve, reject) => {
		const updateUser = httpsCallable(functions, 'updateUser');

		updateUser(user)
		  .then((result) => {
				apiLogger('edge', 'update user', user, result);
		    resolve(result.data)
		  });
	})
}


export async function api_createUser(user) {
	return new Promise(async (resolve, reject) => {
		const createUser = httpsCallable(functions, 'createUser');

		createUser(user)
		  .then((result) => {
				apiLogger('edge', 'create user', user, result);
		    resolve(result.data)
		  });
	})
}


// 88888888ba
// 88      "8b                                           ,d
// 88      ,8P                                           88
// 88aaaaaa8P' ,adPPYba, 88,dPYba,,adPYba,   ,adPPYba, MM88MMM ,adPPYba,
// 88""""88'  a8P_____88 88P'   "88"    "8a a8"     "8a  88   a8P_____88
// 88    `8b  8PP""""""" 88      88      88 8b       d8  88   8PP"""""""
// 88     `8b "8b,   ,aa 88      88      88 "8a,   ,a8"  88,  "8b,   ,aa
// 88      `8b `"Ybbd8"' 88      88      88  `"YbbdP"'   "Y888 `"Ybbd8"'
// 
//   ,ad8888ba,                            ad88 88
//  d8"'    `"8b                          d8"   ""
// d8'                                    88
// 88             ,adPPYba,  8b,dPPYba, MM88MMM 88  ,adPPYb,d8
// 88            a8"     "8a 88P'   `"8a  88    88 a8"    `Y88
// Y8,           8b       d8 88       88  88    88 8b       88
//  Y8a.    .a8P "8a,   ,a8" 88       88  88    88 "8a,   ,d88
//   `"Y8888Y"'   `"YbbdP"'  88       88  88    88  `"YbbdP"Y8
//                                                  aa,    ,88
//                                                   "Y8bbdP"

export async function api_getRemoteConfig() {
	return new Promise(async (resolve, reject) => {		

		const getRemoteConfig = httpsCallable(functions, 'getRemoteConfig');

		getRemoteConfig()
		  .then((result) => {
				apiLogger('edge', 'get remote config', false, '...');

		    resolve(result.data)
		  });
	})
}


// 
//   ,ad8888ba,                       88
//  d8"'    `"8b                      88
// d8'        `8b                     88
// 88          88 8b,dPPYba,  ,adPPYb,88  ,adPPYba, 8b,dPPYba, ,adPPYba,
// 88          88 88P'   "Y8 a8"    `Y88 a8P_____88 88P'   "Y8 I8[    ""
// Y8,        ,8P 88         8b       88 8PP""""""" 88          `"Y8ba,
//  Y8a.    .a8P  88         "8a,   ,d88 "8b,   ,aa 88         aa    ]8I
//   `"Y8888Y"'   88          `"8bbdP"Y8  `"Ybbd8"' 88         `"YbbdP"'
// 


export async function api_getOrders() {
	return new Promise(async (resolve, reject) => {
		let uid = cache.operatorUID.retreive();

		const getLocationOrders = httpsCallable(functions, 'getLocationOrders');

		getLocationOrders(uid)
		  .then((result) => {

				if ( result.data.user.role !== 'master') {
			    cache.locationId.store(result.data.user.locationId)
					setLocation(result.data.location)
				}

				setOrders(result.data.orders)

				weAreLoggedInAsA(result.data.user.role)

				if (result.data.user.role === 'admin') {
					setDisplay(result.data.display);
				}

				apiLogger('edge', 'get orders', uid, result);

		    resolve(result.data.orders)
		  });

	})
}

export async function api_orderDelivery(order) {
	return new Promise(async (resolve, reject) => {		

		const orderDelivery = httpsCallable(functions, 'orderDelivery');

		orderDelivery(order)
		  .then((result) => {

				apiLogger('edge', 'order delivery', order, result);

		    resolve(result.data)
		  });
	})
}


export async function api_orderSubscription(order) {
	return new Promise(async (resolve, reject) => {		

		const orderSubscription = httpsCallable(functions, 'orderSubscription');

		orderSubscription(order)
		  .then((result) => {

				apiLogger('edge', 'order delivery', order, result);

		    resolve(result.data)
		  });
	})
}

export async function api_uploadImage(orderId, data) {
	return new Promise(async (resolve, reject) => {		

		const storageRef = ref(storage, `/delivery/${orderId}.png`);

		uploadString(storageRef, data, 'data_url').then((snapshot) => {
		  console.log('Uploaded a data_url string!');
			resolve()
		});
	})
}

export async function api_updateDeliveryStatus(deliveryId, newStatus) {
	return new Promise(async (resolve, reject) => {		

		const updateDeliveryManual = httpsCallable(functions, 'updateDeliveryManual');

		updateDeliveryManual({deliveryId, newStatus})
		  .then((result) => {

				apiLogger('edge', 'update delivery status', {deliveryId, newStatus}, result);

				resolve(result.data)
		  });
	})
}


// 
// 88888888ba                          88
// 88      "8b                         88
// 88      ,8P                         88
// 88aaaaaa8P'  ,adPPYba,   ,adPPYba,  88   ,d8
// 88""""""8b, a8"     "8a a8"     "8a 88 ,a8"
// 88      `8b 8b       d8 8b       d8 8888[
// 88      a8P "8a,   ,a8" "8a,   ,a8" 88`"Yba,
// 88888888P"   `"YbbdP"'   `"YbbdP"'  88   `Y8a
// 
// 88      a8P                                   88
// 88    ,88'                                    ""
// 88  ,88"
// 88,d88'      ,adPPYba,  ,adPPYba, 8b,dPPYba,  88 8b,dPPYba,   ,adPPYb,d8
// 8888"88,    a8P_____88 a8P_____88 88P'    "8a 88 88P'   `"8a a8"    `Y88
// 88P   Y8b   8PP""""""" 8PP""""""" 88       d8 88 88       88 8b       88
// 88     "88, "8b,   ,aa "8b,   ,aa 88b,   ,a8" 88 88       88 "8a,   ,d88
// 88       Y8b `"Ybbd8"'  `"Ybbd8"' 88`YbbdP"'  88 88       88  `"YbbdP"Y8
//                                   88                          aa,    ,88
//                                   88                           "Y8bbdP"

export async function api_doBookkeeping() {
	return new Promise(async (resolve, reject) => {		

		const doBookkeeping = httpsCallable(functions, 'doBookkeeping');

		doBookkeeping()
		  .then((result) => {

				apiLogger('edge', 'do bookkeeping', false, result);

		    resolve(result.data)
		  });
	})
}


export async function api_getBookkeeping() {
	return new Promise(async (resolve, reject) => {		

		const getBookkeeping = httpsCallable(functions, 'getBookkeeping');

		getBookkeeping()
		  .then((result) => {

				apiLogger('edge', 'get bookkeeping', false, result);

		    resolve(result.data)
		  });
	})
}

// 
//    ,adba,
//    8I  I8
//    "8bdP'
//   ,d8"8b  88  ,adPPYba,
// .dP'   Yb,8I a8"     ""
// 8P      888' 8b
// 8b,   ,dP8b  "8a,   ,aa 888
// `Y8888P"  Yb  `"Ybbd8"' 888
// 


export async function api_getServiceArea() {
	return new Promise(async (resolve, reject) => {		

		const getServiceArea = httpsCallable(functions, 'getServiceArea');

		getServiceArea()
		  .then((result) => {
				apiLogger('edge', 'get service area', false, result);

				setServiceArea(result.data);

				resolve();
		  });
	})
}


export async function api_promoCodeInput(code) {
	return new Promise(async (resolve, reject) => {		

		const promoCodeInput = httpsCallable(functions, 'promoCodeInput');

		promoCodeInput(code)
		  .then((result) => {

				apiLogger('edge', 'promo code input', code, result);

		    resolve(result.data)
		  });
	})
}


export async function api_sendHelpMessage(message) {
	return new Promise(async (resolve, reject) => {		

		const sendHelpMessage = httpsCallable(functions, 'sendHelpMessage');

		sendHelpMessage(message)
		  .then((result) => {

				apiLogger('edge', 'send help message', message, result);

		    resolve(result.data)
		  });
	})
}











