import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import TextStrings from '../../constants/TextStrings'
import Touchable from '../&c./touchable/touchable';
import TextInput from '../&c./textInput/textInput';

import { api_login } from '../../functions/api';
import cache from '../../functions/cache';
import { navigate } from '../../functions/navigator';


export default function Root() {
  let _nav = useNavigate();
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [isLoading, setIsLoading] = useState(true);

  let login = async () => {
    console.log('LOGGING IN WITH', email, password)
    let hasBeenLoggedIn = await api_login(email, password);

    console.log('hasBeenLoggedIn', hasBeenLoggedIn)

    if (hasBeenLoggedIn) {
      navigate(_nav, '/home');
    }
  }

  useEffect(() => {
    let storedEmail = cache.operatorEmail.retreive();
    let storedPassword = cache.operatorPassword.retreive();

    if (!!storedEmail && !!storedPassword) {
      setEmail(storedEmail)
      setPassword(storedPassword)

      login()
    }

    setIsLoading(false)
  }, [])

  return (
    <div className="auth">
      { !isLoading && (

        <div className="authInterior">
          <div className="authLeft">
            <h2>{ TextStrings.auth.title }</h2>            
            <div className="authMasthead"></div>

          </div>
          
          <div className="authRight">

            <TextInput 
              type="email"
              className="authInput"
              value={ email } 
              change={ setEmail }
              label={ TextStrings.auth.email }
              onEnter={ login }
            />

            <TextInput 
              type="password"
              className="authInput"
              value={ password } 
              change={ setPassword }
              label={ TextStrings.auth.password }
              onEnter={ login }
            />

            <Touchable className="authLogin" onPress={ login }>
              <p>{ TextStrings.auth.login }</p>
            </Touchable>
          </div>
        </div>
      )}
    </div>
  );
}