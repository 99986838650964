import React, { useState, useEffect } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';

import { isEmptyObject, displayStripeAmount }  from '../../../../functions/util';
import TextStrings from '../../../../constants/TextStrings';

import { getProducts } from '../../../../context/ProductsContext';

export default function OrderDisplay(props) {
	let [isLoading, setIsLoading] = useState(true);
	let [theOrder, setTheOrder] = useState({});
	let [orderDisplay, setOrderDisplay] = useState([]);
	let [isSubscribed, setIsSubscribed] = useState(false);
  let [totalCost, setTotalCost] = useState();
  let [products, setProducts] = useState({})


	let prepareOrderDisplay = (theOrder, products, isSubscribed) => {
		console.log('THEORDER', theOrder)

		let theOrderDisplay = [];

		let firstItemCost;
		let theTotalCost = 0;

		if (!!theOrder.subscription && !isEmptyObject(theOrder.subscription)) {
			let name = `${TextStrings.subscription[theOrder.subscription.period].adverb} ${TextStrings.subscription.subscription}`;
			let cost = theOrder.subscription.amount;
			let unitCost = cost;
			theTotalCost += cost;
			theOrderDisplay.push({ 
				type: 'subscription',
				name, 
			  unitCost, 
			  cost 
			});

			if (theOrder.hasOwnProperty('coupon') && !isEmptyObject(theOrder.coupon)) {
				let difference = theOrder.subscription.amount * (theOrder.coupon.discount / 100);

				theTotalCost -= difference;

				theOrderDisplay.push({ 
					type: 'coupon',
					name: theOrder.coupon.name, 
					cost: -difference, 
				});
			}

			firstItemCost = products.delivery.first.subscribed;
		} else {

			if (isSubscribed) {
				firstItemCost = products.delivery.first.subscribed
			} else {
				firstItemCost = products.delivery.first.unsubscribed				
			}
		}

		console.log(theOrder.deliveryItems)

		theOrder.deliveryItems.forEach((item, index) => {
			if (index === 0) {
				theTotalCost += firstItemCost;
				theOrderDisplay.push({ 
					type: 'article',
					id: item,
					name: `${TextStrings.order.item} #${item}`,
				  cost: firstItemCost, 
				});
			} else {
					
				theOrderDisplay.push({ 
					type: 'article',
					id: item,
					name: `${TextStrings.order.item} #${item}`,
				  cost: products.delivery.subsequent, 
				});
				theTotalCost += products.delivery.subsequent;
			}
		})

		theOrderDisplay.push({
			type: 'fee',
			name: TextStrings.order.administrativeCharge,
			cost: 35
		});
		theTotalCost += 35;

		console.log('theOrderDisplay', theOrderDisplay)
		console.log('theTotalCost', theTotalCost)

		props.display({
			currency: products.currency,
			total: theTotalCost
		})

		setOrderDisplay(theOrderDisplay);
		setTotalCost(theTotalCost);
	}

	let removeItem = (which) => {
		props.remove(which)
	}

	useEffect(() => {
		let subscriptionStatus = !!props.subscribed || !!props.order.subscribed;
		setIsSubscribed(subscriptionStatus);

		console.log('SUBSCRIPTIONSTATUS, ISSUBSCRIBED')
		console.log(subscriptionStatus, isSubscribed)

		let _currentProducts = getProducts()

		let theOrderProducts = props.products || props.order.productSnapshot;

		if (
			!theOrderProducts.hasOwnProperty('delivery') ||
			!theOrderProducts.hasOwnProperty('subscriptions')
		) {
			theOrderProducts = _currentProducts;
		}

		setProducts(theOrderProducts);

		console.log('THEORDERPRODUCTS, PRODUCTS, ORDER')
		console.log(theOrderProducts, products, props.order)

		prepareOrderDisplay(props.order, theOrderProducts, subscriptionStatus);
		setIsLoading(false)
	}, [props.order]);

	return (
		<div className={`orderItems ${props.isDisplay ? 'isDisplay' : ''}`}>
			<div className="orderItemsHead orderItemRow">
				<div className="orderItemRemove" />
				<p className="orderItemHead orderItemText">{ TextStrings.order.item }</p>
				<p className="orderItemHead orderItemPrice">{ TextStrings.order.price }</p>
			</div>

			{ !!orderDisplay.length ? orderDisplay.map((orderItem, index) => (
				<div 
					key={ index } 
					className="orderItem orderItemRow"
				>
					{ orderItem.type === 'article' ? (
						<div className="orderItemRemove" onClick={ () => removeItem(orderItem.id) }>
							<CancelIcon />
						</div>
					) : (
						<div className="orderItemRemove" />
					)}
					<p className="orderItemText">{ orderItem.name }</p>
					<p className="orderItemPrice">
						{ displayStripeAmount(orderItem.cost) }
					</p>
				</div>
			)) : (
				<>
					<div className="noOrderItems orderItemRow">				
						<p className="orderItemText">{ TextStrings.order.noOrderItems1 }</p>
					</div>
					<div className="noOrderItems orderItemRow">				
						<p className="orderItemText">{ TextStrings.order.noOrderItems2 }</p>
					</div>
				</>
			)}

			<div className="orderTotal orderItemRow">
				<div className="orderItemRemove" />
				<p className="orderTotalTitle orderItemText">
					{ TextStrings.order.total }
					<span className="orderItemText_currency">
						{ `${products.currency?.toUpperCase()}` }&nbsp;
					</span>
				</p>
				<p className="orderTotalSum orderItemPrice">
					{ `${displayStripeAmount(totalCost)}` }
				</p>
			</div>
		</div>

	)

}
