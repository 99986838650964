import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import RefreshIcon from '@mui/icons-material/Refresh';

import Touchable from '../../&c./touchable/touchable';

import { api_getOrders, api_searchUser } from '../../../functions/api';
import { setTitle } from '../../../functions/title';
import { 
  convertTimestampToHumanReadable_hourMinute
} from '../../../functions/util';
import { navigate } from '../../../functions/navigator';

import { getOrders } from '../../../context/OrdersContext';

import TextStrings from '../../../constants/TextStrings'

export default function Orders() {
  let _nav = useNavigate();
  let [orders, setOrders] = useState([])
  let [isLoading, setIsLoading] = useState(false);

  let hello = async () => {
    setIsLoading(true)

    let theOrders = await getOrders()
    setTitle(TextStrings.orders.title)

    // let theSearch = await api_searchUser();

    console.log(theOrders)
    // console.log(theSearch)

    setOrders(theOrders);
    setIsLoading(false)
  }

  let refresh = async () => {
    setIsLoading(true)
    setOrders([]);
    await api_getOrders();
    await hello();
  }

  useEffect(() => {
    hello()
  }, [])

  return (
    <div className="orders">
      <h1>{ TextStrings.orders.title } <RefreshIcon onClick={ refresh }/></h1>
      { 
        orders.length ? orders
          .sort((a, b) => b.orderTimestamp - a.orderTimestamp)
          .map((order, index) => {
            return (
              <Touchable 
                key={ order.id }
                className="order" 
                onPress={ () => navigate(_nav, `/home/order/${order.id}`) }>
                <p className="order_id">{ order.id }</p>
                <p>{ convertTimestampToHumanReadable_hourMinute(order.orderTimestamp) }</p>
                <p>{ TextStrings.order.deliveryStatuses[order.deliveryStatus] }</p>
              </Touchable>
            )
          })
        : (
          isLoading ? (
            <div className="ordersFloater">
              <p>{ TextStrings.general.loading }</p>
            </div>
          ) : (
            <div className="ordersFloater">
              <p>{ TextStrings.orders.noOrders }</p>
            </div>
          )
        )
      }
    </div>
  );
}