let navigationStack = [];

export function navigate(_nav, to) {
	console.log('navigated!!', _nav, typeof _nav, to)

	let where = to;

	if (to === -1) {
		navigationStack.pop();
		where = navigationStack.pop();
		if (where.indexOf('new') >= 0) {
			where = navigationStack.pop();
		}
	} 

	navigationStack.push(where);

	console.log(navigationStack)

	_nav(where);
}