import React, { useState, useEffect, useRef } from 'react';
import QrScanner from 'qr-scanner';
import { useParams, Outlet, useNavigate } from 'react-router-dom';

import KeyboardIcon from '@mui/icons-material/Keyboard';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AddIcon from '@mui/icons-material/Add';
import CameraIcon from '@mui/icons-material/Camera';
import ReplayIcon from '@mui/icons-material/Replay';
import CheckIcon from '@mui/icons-material/Check';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';

import './lens.less';

import Touchable from '../../../&c./touchable/touchable';

import { api_getUser } from '../../../../functions/api';
import TextStrings from '../../../../constants/TextStrings'
import { displayStripeAmount, makeRandomHash } from '../../../../functions/util';

export default function Lens(props) {
	let parentElement;
	let video
	let videoRef = useRef();
	let canvas
	let canvasRef = useRef();
	// let stream

	let manualInputRef = useRef();
	let [theScanner, setTheScanner] = useState();

	// let lens// = document.querySelector(".lens");

	let [stream, setStream] = useState()

	let [canScan, setCanScan] = useState(true);
	let [lastCode, setLastCode] = useState('');
	let [isScanner, setIsScanner] = useState(true)
	let [isCamera, setIsCamera] = useState(false)

	let [manualInputDisplay, setManualInputDisplay] = useState(false);
	let [manualCode, setManualCode] = useState('');

	let [pictureTaken, setPictureTaken] = useState(false)
	let [thePicture, setThePicture] = useState('');

	// let [cameraData, setCameraData] = useState({});

	// const [windowWidth, setWindowWidth] = useState(0);
 //  const [windowHeight, setWindowHeight] = useState(0);

  let [lensHeight, setLensHeight] = useState();
  let [lensWidth, setLensWidth] = useState();

  let [showCameraSwitcher, setShowCameraSwitcher] = useState(false);
  let [cameraDirection, setCameraDirection] = useState('environment');
  let [noCamera, setNoCamera] = useState(false);

  let resizeWindow = () => {
    // setWindowWidth(window.innerWidth);
    // setWindowHeight(window.innerHeight);

    setTimeout(() => {

	    let _lens = document.querySelector('.lens');
	    setLensHeight(_lens.offsetHeight)
	    setLensWidth(_lens.offsetWidth)
    }, 500)


    console.log('RESIZE')
  };

  let switchCamera = () => {
  	console.log('SWITCH THE CAMERA')
  	if (cameraDirection === 'environment') {
  		setCameraDirection('user')
  	} else {
  		setCameraDirection('environment')
  	}

  	console.log('SWITCH! cameraDirection!!', cameraDirection)

  	theScanner.setCamera(cameraDirection);
  }

  let stopCamera = () => {
  	theScanner?.stop();
  	theScanner?.destroy();
  	setTheScanner(null)
  }

	let hello = async () => {

		let _video = document.querySelector(".video");

		console.log(_video)

		let _theScanner = new QrScanner(
	    _video,
	    readCode, 
	    { 
	    	onDecodeError: () => {},
	    	preferredCamera: cameraDirection
	    }
		);
		console.log('SCANNERED')

		await _theScanner.start();

		console.log('SCANNERED STARTED')

		setTheScanner(_theScanner)
	}

	useEffect(() => {
		hello()

		resizeWindow();
    window.addEventListener("resize", resizeWindow);

		return () => {
			window.removeEventListener("resize", resizeWindow);
			stopCamera()
		}
	}, [])

	useEffect(() => {
		if (props.scanner) {
			setIsScanner(true)
			setIsCamera(false)
		}
		if (props.camera) {
			setIsCamera(true)
			setIsScanner(false)
		}
	}, [props.scanner, props.camera])

	let fakeCode = () => {
		let theFakeCode = makeRandomHash();
		readCode({data: theFakeCode})
	}

	let handleKeyDown = (keyCode) => {
	  console.log(keyCode)
	  if (keyCode === 13) {
	    enterManualCode();
	  }
	}

	let readCode = (code) => {
		console.log(
			'code', code, 
		  'lastCode', lastCode, 
		  'isScanner', isScanner, 
		  'canScan', canScan, 
		  '(code !== lastCode)', (code !== lastCode)
	  )
		if (isScanner && canScan && (code !== lastCode)) {
			setLastCode(code)
			props.code(code.data)
		}
		setCanScan(false)

		setTimeout(() => {
			setCanScan(true)
		}, 2000)
	}

	let reTakePicture = () => {
		video = document.querySelector(".video");
		canvas = document.querySelector(".canvas");
		let context = canvas.getContext('2d');
		context.clearRect(0, 0, canvas.width, canvas.height);

		setPictureTaken(false)
	}

	let sendPicture = () => {
		props.image(thePicture)
	}

	let takePicture = (img) => {
		let video = document.querySelector(".video");
		let canvas = document.querySelector(".canvas");

		let streamSettings = video.srcObject.getVideoTracks()[0].getSettings();

		const imageWidth = streamSettings.width;
    const imageHeight = streamSettings.height;

    const scale = Math.min(lensWidth/imageWidth, lensHeight/imageHeight);

    const scaledWidth = imageWidth * scale;
    const scaledHeight = imageHeight * scale;

    canvas.getContext('2d').drawImage(
    	video,
    	(lensWidth - scaledWidth) / 2, 
    	(lensHeight - scaledHeight) / 2, 
    	scaledWidth, 
    	scaledHeight
  	);

   	let pictureData = canvas.toDataURL('image/jpeg');

   	setThePicture(pictureData)

		setPictureTaken(true);
	}

	let toggleManualInputDisplay = () => {
		let _manualInputDisplay = manualInputDisplay;
		setManualInputDisplay(!_manualInputDisplay);

		if (manualInputDisplay) {
			setCanScan(false);
			manualInputRef.current.focus();
		}
	}

	let enterManualCode = () => {
		props.code(manualCode)
		setManualCode('')
	}

	return (
		<div className="lens">

			<video 
				ref={ videoRef } 
				height={ lensHeight } 
				width={ lensWidth } 
				className={`video ${pictureTaken ? '' : 'display'}`} 
				autoPlay
			></video>

			<canvas 
				ref={ canvasRef } 
				height={ lensHeight } 
				width={ lensWidth } 
				className={`canvas ${pictureTaken ? 'display': ''}`} 
			></canvas>

			<Touchable className="camera_button camera_button_switchCamera" onPress={ switchCamera }>
				<CameraswitchIcon/>
			</Touchable>

			{ isScanner && (
				<>
					{!noCamera && (
						<Touchable className="scanner_manualInputButton" onPress={ toggleManualInputDisplay }>
							{ manualInputDisplay ? <QrCodeIcon /> : <KeyboardIcon /> }
						</Touchable>
					)}
					
					{ manualInputDisplay ? (
							<div className="scanner_manualInput">
								<input 
									ref={ manualInputRef }
									value={ manualCode } 
									onKeyDown={ e => handleKeyDown(e.keyCode) }
								  onChange={ e => setManualCode(e.target.value) }
							  />

								<Touchable className="scanner_manualInputConfirm" onPress={ enterManualCode } >
									<AddIcon />
								</Touchable>

							</div>
						) : (
							<div className="scanner_QRFrame">
								<span></span>
								<span></span>
								<span></span>
								<span></span>
							</div>
						)
					}
				</>
			)}

			{ isCamera && (
				<>
					{ pictureTaken && (
						<Touchable className="camera_reTakePicture" onPress={ reTakePicture }>
							<ReplayIcon />
						</Touchable>
					)}
					{ pictureTaken ? (
						<Touchable className="camera_takePicture" onPress={ sendPicture }>
								<CheckIcon />
						</Touchable>
					) : (					
						<Touchable className="camera_takePicture" onPress={ takePicture }>
								<CameraIcon />
						</Touchable>
					)}
				</>
			)}
		</div>
	)

}