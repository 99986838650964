import React, { useState, useEffect } from 'react';
import { useParams, Outlet, useNavigate } from 'react-router-dom';

import Touchable from '../../../&c./touchable/touchable';

import { api_getUser } from '../../../../functions/api';
import TextStrings from '../../../../constants/TextStrings'
import { 
  displayStripeAmount, 
} from '../../../../functions/util';

export default function Order(props) {
	// let { userId } = useParams();

	let [user, setUser] = useState({})

	let [orderSending, setOrderSending] = useState(false);
	let [orderSent, setOrderSent] = useState(false);
	let [canConfirmCheckout, setCanConfirmCheckout] = useState(false);

	let hello = async () => {
		// let theUser = await api_getUser(userId);
		// console.log(theUser)
		// setUser(theUser)
	}

	useEffect(() => {
		hello()
	}, [])

	useEffect(() => {
		setCanConfirmCheckout(props.canConfirm)
	}, [props.canConfirm])

	let checkoutConfirm = () => {
		setOrderSending(true)
		props.confirm();
	}

	return (
		<div className="checkout">
			<h1>{ TextStrings.checkout.head }</h1>

			<p>{ TextStrings.checkout.lede }</p>

			<ul>
				<li>{ TextStrings.checkout.step_1 }</li>
				<li>
					{ TextStrings.checkout.step_2[0] }
					<b>
						{ props.display.currency.toUpperCase() }
						{ ' ' }
						{ displayStripeAmount(props.display.total) }
					</b>
					{ TextStrings.checkout.step_2[1] }
				</li>
			</ul>

			<p>{ TextStrings.checkout.somethingMissing }</p>
			
			<Touchable 
				onPress={ checkoutConfirm }
				inactive={ !canConfirmCheckout }
				loading={ orderSending }
			>
				<p>{ TextStrings.checkout.send }</p>
			</Touchable>
			
		</div>
	)

}