import React, { useState, useEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export default function Select(props) {
	let [options, setOptions] = useState({})
	let [chosenOption, setChosenOption] = useState('');

	let [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		let _options = props.options;
		setOptions(_options);
	}, []);

	useEffect(() => {
		let _selected = props.selected;

		setChosenOption(_selected)
	}, [props.selected])

	let toggleIsOpen = () => {
		if (isOpen) setIsOpen(false)
		else setIsOpen(true)
	}

	let selectOption = (option) => {
		props.onSelect(option)
		setChosenOption(option)
		setIsOpen(false)
	}

	return (
		<div className={ `select ${isOpen ? 'open' : ''}` }>
			<div className="selectDisplay" onClick={ toggleIsOpen }>
				{ options[chosenOption] }

				<div className="selectDisplayArrow">
					{ isOpen ? (
						<ArrowDropUpIcon />
					) : (
						<ArrowDropDownIcon />
					)}
				</div>
			</div>

			<div className="selectOptions">
				{ Object.keys(options).map((option, index) => {
					return (
						<div 
							className={`option ${option === props.selected ? 'selected' : ''}`} 
							onClick={ () => selectOption(option) }>
							{options[option]}
						</div>
					)
				})}
			</div>
		</div>
	)
}