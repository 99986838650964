import { useRouteError, useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';

import TextStrings from '../../../constants/TextStrings'
import { navigate } from '../../../functions/navigator';

export default function Error() {

  let _nav = useNavigate();
  const error = useRouteError();

  console.error(error);

  return (
    <div className="errorPage">
      <div className="errorPageInterior">        
        <h1>{ TextStrings.error.title }</h1>
        <p>{ TextStrings.error.message }</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
       <HomeIcon className="error_goHome" onClick={ () => navigate(_nav, '/home') }/>
      </div>
    </div>
  );
}