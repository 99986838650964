let orders = [];

export async function getOrders() {
	return orders;
}

export async function getOrder(which) {
	let filteredOrders = orders.filter(order => order.id === which)
	return filteredOrders[0]
}

export async function setOrders(incoming) {
	orders = incoming;
}