let loggedIn = false;
let userRole = '';

export async function areWeLoggedIn() {
	return loggedIn;
}

export async function setLoggedInStateTo(state) {
	loggedIn = state;
	// letEmKnow();
}

export async function weAreLoggedInAsA(who) {
	userRole = who;
}

export async function theCurrentUserIsA() {
	return userRole;
}