import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CancelIcon from '@mui/icons-material/Cancel';

import Touchable from '../../&c./touchable/touchable';

import { api_searchUser } from '../../../functions/api';
import { setTitle } from '../../../functions/title';
import { navigate } from '../../../functions/navigator';

import { storeSearchResults, retreiveSearchResults } from '../../../context/SearchResultsContext';

import TextStrings from '../../../constants/TextStrings'

export default function Search() {
	let inputRef = useRef();
  let _nav = useNavigate();
	let { searchQuery } = useParams();

	let [query, setQuery] = useState('');
	let [isSearching, setIsSearching] = useState(false)
	let [hasSearched, setHasSearched] = useState(false);
	let [searchResults, setSearchResults] = useState([])

	let search = async () => {
		setIsSearching(true)
		setTitle(`${TextStrings.search.titleAction} '${query}'`)

		let searchResult = await api_searchUser(query);
		console.log('searchResult', searchResult)
		setIsSearching(false)
		setSearchResults(searchResult);
		setHasSearched(true);

	}

	let handleKeyDown = (keyCode) => {
		if (keyCode === 13) {
			search(query);
		}
	}

	let highlightSearchResults = (string) => {
		if (!!string) {
	  	let highlighted = string;
			let index = string.toLowerCase().indexOf(query.toLowerCase());
		  if (index >= 0) { 
		  	return (
		  		<>
			  		{ string.substring(0, index) }
			  		<span className="highlight"> 
			  		{ string.substring(index, index + query.length) }
			  		</span> 
			  		{ string.substring(index + query.length) }
		  		</>
	  		)
		  } else {
		  	return string;
		  }
		}
	}

	let hello = async () => {
		inputRef.current.focus();
		setTitle(TextStrings.search.title)
	}

	useEffect(() => {
		let _storedResults = retreiveSearchResults();
		console.log('_storedResults', _storedResults)
		if (!!_storedResults) {
			setQuery(_storedResults.query);
			setSearchResults(_storedResults.results);
		}
		storeSearchResults('', '')
		hello()
	}, [])

	let gotoUser = (who) => {
		console.log('GOTO', who)
		storeSearchResults(query, searchResults);
		console.log('WHO???', who)
		navigate(_nav, `/home/user/${who}`)
	}

	let clearSearchBar = () => {
		setQuery('');
		setSearchResults('')
		setHasSearched(false);
		console.log(query)
	}

	return (
		<div className="search">
			<div className="searchBar">

				<div className="searchClear" onClick={ clearSearchBar } >
					<CancelIcon />
				</div>
				
				<input 
					ref={ inputRef }
					value={ query } 
				  onChange={ e => setQuery(e.target.value) }
				  onKeyDown={ e => handleKeyDown(e.keyCode)  }
			  />

				<button onClick={ search }>
				  <SearchIcon />
				</button>
			</div>
			
			<div className="searchResults">

				{
					isSearching && (
						<div className="searchFloater">
							<p>{ `${TextStrings.search.searching} '${query}'...` }</p>
						</div>
				)}
					
				{
					!!searchResults.length && searchResults.map((result, index) => {
						return (
							<div 
								className="searchResult" 
								key={ result.item.uid + result.item.email } 
								onClick={ () => gotoUser(result.item.uid) }
								>

								<div className="searchResultName">
									{ highlightSearchResults(result.item.address?.firstName) }
									<br />
									{ highlightSearchResults(result.item.address?.lastName) }
								</div>

								<div className="searchResultCompany">
									{ highlightSearchResults(result.item.address?.mobile) }
									<br />
									{ highlightSearchResults(result.item.address?.companyName) }
								</div>

								<div className="searchResultAddress">									
									{ highlightSearchResults(result.item.address?.line1) }
									<br />
									{ highlightSearchResults(result.item.address?.line2) }
								</div>

								<div className="searchResultCompany">
									{ highlightSearchResults(result.item.address?.zip) }&nbsp;
									{ highlightSearchResults(result.item.address?.city) }
									<br />
									{ highlightSearchResults(result.item.email) }
								</div>

							</div>	
						)
					})
				}

				{ 
					(!searchResults.length && hasSearched) && (

					<div className="searchFloater noResults">
						<p>{ TextStrings.search.noResults }</p>
						<Touchable className="noResults_newUser" onPress={ () => navigate(_nav, '/home/user/new') }>
							<PersonAddIcon />
							<p>{ TextStrings.search.newUser }</p>
						</Touchable>
					</div>
				)}
			</div>
		</div>

	);
}