import React, { useState, useEffect } from 'react';

import TextStrings from '../../../constants/TextStrings'

export default function Loader() {

	return (
		<div className="loader">
		  <p>{ TextStrings.general.loading }</p>

		  <div className="ring"></div>
		</div> 
	)
}