import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import '@fontsource/urbanist/300.css';
import '@fontsource/urbanist/400.css';
import '@fontsource/urbanist/500.css';
import '@fontsource/urbanist/700.css';

import "@fontsource/space-mono"

import './main.less'

import Error from "./views/&c./routeError/routeError";

import Auth from "./views/auth/auth";
import Root from './views/main/_root/root'
import BookKeeping from "./views/main/bookKeeping/bookKeeping";
import Locations from './views/main/locations/locations';
import Dashboard from './views/main/dashboard/dashboard';
import Orders from "./views/main/orders/orders"
import Search from './views/main/search/search';
import User from './views/main/user/user';

import Order from './views/main/order/order';

Sentry.init({
  dsn: "https://a6699674ef224b61bc26b65e676a1aa0@o4504491124785152.ingest.sentry.io/4504895672680448",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});


const router = createHashRouter([
  {
    path: "/",
    element: <Auth />,
    errorElement: <Error />,
  },
  {
    path: "/home",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: 'bookkeeping', 
        element: <BookKeeping />
      },
      {
        path: 'locations', 
        element: <Locations />
      },
      {
        path: 'dashboard', 
        element: <Dashboard />
      },
      {
        path: 'orders',
        element: <Orders />,
      },
      {
        path: 'search',
        element: <Search />,
      },
      {
        path: 'user/:userId',
        element: <User />,
      },
      {
        path: 'order/:orderId',
        element: <Order />,
      },

    ],
  },
]);


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
