import React, { useState, useEffect } from 'react';
import { useParams, Outlet, useNavigate } from 'react-router-dom';

import Touchable from '../../../&c./touchable/touchable';

import TextStrings from '../../../../constants/TextStrings'
import { api_promoCodeInput } from '../../../../functions/api';

export default function Order(props) {
	let [code, setCode] = useState('');

	let [codeAttempted, setCodeAttempted] = useState(false)

	let [attemptResult, setAttemptResult] = useState();

	let [codeTrying, setCodeTrying] = useState(false);
	let [codeSucceeded, setCodeSucceeded] = useState(false);

	let hello = async () => {
		// let theUser = await api_getUser(userId);
		// console.log(theUser)
		// setUser(theUser)
	}

	useEffect(() => {
		hello()
	}, [])

	let codeConfirm = () => {
		props.attempt(code);
	}

	let tryCode = async () => {
		setCodeTrying(true);
		let result = await api_promoCodeInput(code);
		console.log(result)

		setCodeAttempted(true)
		setAttemptResult(!!result.match);

		setCodeSucceeded(true)
		setCodeTrying(false);

		if (!!result.match) {
			props.codeEntry(result)
		}
	}

	// console.log(userId)

	return (
		<div className="discountCode">

			<h3>{ TextStrings.code.title }</h3>

			<input value={ code } onChange={ e => setCode(e.target.value.toUpperCase()) }></input>

			<p>{ TextStrings.code.prompt }</p>

			{ (codeAttempted && attemptResult) && (
				<h3 className="codeResult success">{ TextStrings.code.codeSuccess }</h3>	
			)}

			{ (codeAttempted && !attemptResult) && (
				<h3 className="codeResult failure">{ TextStrings.code.codeFailure }</h3>	
			)}

			<Touchable 
				className="scanner_manualInputConfirm" 
				inactive={ code === '' }
				loading={ codeTrying }
				success={ codeSucceeded }
				onPress={ tryCode } >
				<p>{ TextStrings.code.tryCode }</p>
			</Touchable>
			
		</div>
	)

}