import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

import Loader from '../../&c./loader/loader';

import Touchable from '../../&c./touchable/touchable';

import { api_getOrders, api_searchUser } from '../../../functions/api';
import { setTitle } from '../../../functions/title';
import { navigate } from '../../../functions/navigator';

import { 
  convertTimestampToHumanReadable_hourMinute,
  displayStripeAmount
} from '../../../functions/util';


import { theCurrentUserIsA } from '../../../context/LoggedInContext';
import { getDisplay } from '../../../context/DisplayContext';

import TextStrings from '../../../constants/TextStrings'

export default function Orders() {
  let _nav = useNavigate();
  let [orders, setOrders] = useState([])
  let [isLoading, setIsLoading] = useState(true);

  let [display, setDisplay] = useState({})

  let [byMonth, setByMonth] = useState({})
  let [byWeekday, setByWeekday] = useState({})
  let [byHour, setByHour] = useState({})
  let [delivered, setDelivered] = useState({})
  let [inTotal, setInTotal] = useState({})
  let [revenue, setRevenue] = useState({})

  let chartOptions = {
    plugins: {
      legend: {
          display: false
      },
    },
    scales: {
      y: {
        ticks: { color: 'white' }
      },
      x: {
        ticks: { color: 'white' }
      }
    }
  }

  let hello = async () => {
    let _userRole = await theCurrentUserIsA();
    setTitle(TextStrings.dashboard.title)

    if (_userRole !== 'admin') {
      navigate(_nav, '/home/orders')
    }

    let theDisplay = await getDisplay();

    setDisplay(theDisplay);
    setIsLoading(false);
  }

  useEffect(() => {
    hello()
  }, [])

  return (
    <div className="dashboard">
      { isLoading ? (
        <Loader />
      ) : (
        <>          
          <h1>{ TextStrings.dashboard.title }</h1>

          <div className="dashboard_cell">
            <h3>{ TextStrings.dashboard.inTotal }</h3>

            <h1>{ display.deliveries.inTotal }</h1>
            
          </div>

          <div className="dashboard_cell">
            <h3>{ TextStrings.dashboard.delivered }</h3>

            <h2>{ display.deliveries.delivered.delivered } / { display.deliveries.delivered.notDelivered }</h2>
          </div>

          <div className="dashboard_cell">
            <h3>{ TextStrings.dashboard.revenue }</h3>
            <h2><span>CHF</span> { displayStripeAmount(display.deliveries.revenue) }</h2>
          </div>

          <div className="dashboard_cell">
            <h3>{ TextStrings.dashboard.byHour }</h3>
            <Bar
              data={{
                labels: Object.keys(display.deliveries.byHour),
                datasets: [{
                  data: Object.values(display.deliveries.byHour),
                  backgroundColor: '#ffcd00'
                }],
              }}
              options={ chartOptions }
            />
          </div>

          <div className="dashboard_cell">
            <h3>{ TextStrings.dashboard.byWeekday }</h3>
            <Bar
              data={{
                labels: TextStrings.dashboard.days,
                datasets: [{
                  data: Object.values(display.deliveries.byWeekday),
                  backgroundColor: '#ffcd00'
                }],
              }}
              options={ chartOptions }
            />
          </div>

          <div className="dashboard_cell">
            <h3>{ TextStrings.dashboard.byMonth }</h3>
            <Bar
              data={{
                labels: Object.keys(display.deliveries.byMonth),
                datasets: [{
                  data: Object.values(display.deliveries.byMonth),
                  backgroundColor: '#ffcd00'
                }],
              }}
              options={ chartOptions }
            />
          </div>
        </>
      )}
    </div>
  );
}