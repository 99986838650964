
export function makeRandomHash() {
	let theHash = (Math.random() + 1).toString(36).substring(2);
	return theHash
}

export function deepFreeze(object) {
  const propNames = Reflect.ownKeys(object);

  for (const name of propNames) {
    const value = object[name];

    if ((value && typeof value === "object") || typeof value === "function") {
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
}

export function displayStripeAmount(amount) {
  let _amount = [amount?.toString().slice(0, -2), '.', amount?.toString().slice(-2)].join('');

  if (_amount.indexOf('.') === 0) {
    _amount = '0' + _amount;
    return _amount;
  } else {
  	return _amount;
  }
}

export function isNumber(value) {
	return typeof value === 'number' && isFinite(value);
}

export function isEmptyObject(obj) {
  if (obj === undefined) return true; 
  
  return Object.keys(obj).length === 0;
}

export function convertTimestampToHumanReadable(timeStamp) {
	let date = new Intl.DateTimeFormat('de-CH', { 
		year: 'numeric', 
		month: '2-digit', 
		day: '2-digit', 
	}).format(timeStamp)

	return date;
}
export function convertTimestampToHumanReadable_hourMinute(timeStamp) {
  let date = new Intl.DateTimeFormat('de-CH', { 
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit', 
    hour: '2-digit',
    minute: '2-digit',
  }).format(timeStamp)

  return date;
}