import React, { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";

import { navigate } from '../../../functions/navigator';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export default function Head(props) {

  let backButton = () => {
    !!props.beforeBack && props.beforeBack();
    navigate(props._nav, -1)
  }

  return (
    <div className="head">
      <div className="headBack" onClick={ backButton }>
        <KeyboardBackspaceIcon />
      </div>
      <div className={`headContent ${props.monospace ? 'mono' : ''}`}>
        { props.title }
        { !!props.subtitle && (
          <span>{ props.subtitle }</span>  
        )}
      </div>
    </div>
  );
}