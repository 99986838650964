import React, { useState, useEffect, useRef } from 'react';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

import Opener from '../../&c./opener/opener';
import Touchable from '../../&c./touchable/touchable';

import TextStrings from '../../../constants/TextStrings'

import { 
	convertTimestampToHumanReadable,
	convertTimestampToHumanReadable_hourMinute,
	displayStripeAmount
} from '../../../functions/util';

export default function BookKeepingLocation(props) {
	let [isLoading, setIsLoading] = useState(true);
	let [locationOpen, setLocationOpen] = useState(false);

	let [ordersPresence, setOrdersPresence] = useState(false);
	let [subsPresence, setSubsPresence] = useState(false);

	let [ordersCSVhref, setOrdersCSVhref] = useState('');
	let [ordersCSVTitle, setOrdersCSVTitle] = useState('');

	let [subsCSVhref, setSubsCSVhref] = useState('');
	let [subsCSVTitle, setSubsCSVTitle] = useState('');

	let [generatingOrdersCSV, setGeneratingOrdersCSV] = useState(false);
	let [generatedOrdersCSV, setGeneratedOrdersCSV] = useState(false);

	let [generatingSubsCSV, setGeneratingSubsCSV] = useState(false);
	let [generatedSubsCSV, setGeneratedSubsCSV] = useState(false);

	let toggleLocationOpen = () => {
		setLocationOpen(!locationOpen);

		if (!locationOpen) {
			setOrdersCSVhref('');
			setOrdersCSVTitle('');
			setSubsCSVhref('');
			setSubsCSVTitle('');
			setGeneratedOrdersCSV(false);
			setGeneratedSubsCSV(false);
		}
	}

	let composeOrdersCSV = (list) => {
		setGeneratingOrdersCSV(true);

		let ordersHead = []
		let ordersBody = []

		let _orders = 'Datum,Name,Anzahl Artikeln,Summe\n';

		props.location.orders.forEach((order, index) => {
			_orders += `${convertTimestampToHumanReadable(order.date)},${order.name},${order.articleCount},${displayStripeAmount(order.total)}\n`;
		})

		const blob = new Blob([_orders], { type: 'text/csv;charset=utf-8,' })
		const objUrl = URL.createObjectURL(blob)

		setOrdersCSVhref(objUrl)

		let titleRun = convertTimestampToHumanReadable_hourMinute(props.run).replace(', ', '_').replace(':', '');
		let titleLocation = props.location.location.companyName.replace(' ', '_');

		let _csvTitle = `${titleRun}_${titleLocation}_${TextStrings.bookKeeping.ordersFilename}.csv`;
		setOrdersCSVTitle(_csvTitle)

		setGeneratingOrdersCSV(false);
		setGeneratedOrdersCSV(true)
	}

	let composeSubsCSV = (list) => {
		setGeneratingSubsCSV(true);

		let _subs = 'Datum,Name,Art,Rabatt,Summe\n';

		props.location.subscriptions.forEach((sub, index) => {
			_subs += `${convertTimestampToHumanReadable(sub.date)},${sub.name},${sub.type},${sub.discount},${displayStripeAmount(sub.amount)}\n`;
		})

		const blob = new Blob([_subs], { type: 'text/csv;charset=utf-8,' })
		const objUrl = URL.createObjectURL(blob)

		setSubsCSVhref(objUrl)

		let titleRun = convertTimestampToHumanReadable_hourMinute(props.run).replace(', ', '_').replace(':', '');
		let titleLocation = props.location.location.companyName.replace(' ', '_');

		let _csvTitle = `${titleRun}_${titleLocation}_${TextStrings.bookKeeping.subscriptions}.csv`;
		setSubsCSVTitle(_csvTitle)

		setGeneratingSubsCSV(false);
		setGeneratedSubsCSV(true)
	}

	useEffect(() => {
		console.log('LOCATION', props.location )
		if (!!props.location) {
			setIsLoading(false);

			if (
				props.location.hasOwnProperty('orders') 
				&& props.location.orders.length
			){
				setOrdersPresence(true)
			}
			if (
				props.location.hasOwnProperty('subscriptions') 
				&& props.location.subscriptions.length
			){
				setSubsPresence(true)
			}
		}
	}, [props.location])

	return (
		!isLoading && (

			<div key={ props.index } className={`bookKeeping_run_location ${locationOpen ? 'location_open': ''}`}>

				<div className="bookKeeping_run_location_head" >
					<Opener state={ locationOpen } onPress={ toggleLocationOpen }/>
					<h3>{ props.location.location.companyName }</h3>

					{ locationOpen && (
						<>
							<p>{ props.location.location.line1 }</p>
							<p>
								{ props.location.location.zip }
								&nbsp;
								{ props.location.location.city }
							</p>

							<div className="bookKeeping_getFiles">
								{ordersPresence && (

									<div className="bookKeeping_getFile">
										<Touchable 
											onPress={ composeOrdersCSV }
											className={`location_generate orderCSV`}
										>
											<p>{ TextStrings.bookKeeping.ordersCSVGenerate }</p>	
										</Touchable>

										{ generatedOrdersCSV && (
											<a className="downloader" href={ ordersCSVhref } download={ ordersCSVTitle }>
												<DownloadForOfflineIcon fontSize="large"/>
											</a>
										)}
										
									</div>
								)}

								{subsPresence && (
									<div className="bookKeeping_getFile">
										<Touchable 
											onPress={ composeSubsCSV }
											className={`location_generate subCSV`}
										>
											<p>{ TextStrings.bookKeeping.subsCSVGenerate }</p>	
										</Touchable>

										{ generatedSubsCSV && (
											<a className="downloader" href={ subsCSVhref } download={ subsCSVTitle }>
												<DownloadForOfflineIcon fontSize="large"/>
											</a>
										)}
										
									</div>
								)}
								
							</div>
						</>	
					)}
				</div>

				<div className={ `bookKeeping_run_location_body` }>
					
					<div className="bookKeeping_run_column">
						<h4>{ TextStrings.bookKeeping.orders }</h4>

						{ ordersPresence ? (
							<>
								<div className="bookKeeping_run_location_entry">
									<span>{ TextStrings.bookKeeping.date }</span>
									<span>{ TextStrings.bookKeeping.name }</span>
									<span>{ TextStrings.bookKeeping.articleCount }</span>
									<span>{ TextStrings.bookKeeping.orderTotal }</span>
								</div>

								{
									props.location.orders.map((order, index) => {
										return (
											<div key={ order.date + index } className="bookKeeping_run_location_entry">
												<span>{ convertTimestampToHumanReadable(order.date) }</span>
												<span>{ order.name }</span>
												<span>{ order.articleCount }</span>
												<span>{ displayStripeAmount(order.total) }</span>
											</div>
										)
									})
								}
							</>
						) : (
							<div className="bookKeeping_run_location_empty">{ TextStrings.bookKeeping.noOrders }</div>
						)}

					</div>

					<div className="bookKeeping_run_column">
						<h4>{ TextStrings.bookKeeping.subscriptions }</h4>

						{ subsPresence ? (
							<>
								<div className="bookKeeping_run_location_entry">
									<span>{ TextStrings.bookKeeping.date }</span>
									<span>{ TextStrings.bookKeeping.name }</span>
									<span>{ TextStrings.bookKeeping.period }</span>
									<span>{ TextStrings.bookKeeping.discount }</span>
									<span>{ TextStrings.bookKeeping.orderTotal }</span>
								</div>
								
								{
									props.location.subscriptions.map((subscription, index) => (
										<div key={ subscription.date + index } className="bookKeeping_run_location_entry">
											<span>{ convertTimestampToHumanReadable(subscription.date) }</span>
											<span>{ subscription.name }</span>
											<span>{ TextStrings.subscription[subscription.type].period }</span>
											<span>{ subscription.discount ? `${subscription.discount} %` : TextStrings.bookKeeping.noCode }</span>
											<span>{ displayStripeAmount(subscription.amount) }</span>
										</div>
									))
								}
							</>
						) : (
							<div className="bookKeeping_run_location_empty">{ TextStrings.bookKeeping.noSubs }</div>							
						)}
						
					</div>

				</div>

				<div className="bookKeeping_run_footer">
					<div className="bookKeeping_run_column">
						{ordersPresence && (
							<div className="bookKeeping_run_location_entry">
								<span>{ props.location.totals?.orders.count }</span>
								<span>&nbsp;</span>
								<span>{ props.location.totals?.orders.articles }</span>
								<span>{ displayStripeAmount(props.location.totals?.orders.amount) }</span>
							</div>
						)}
					</div>

					<div className="bookKeeping_run_column">
						{subsPresence && (
							<div className="bookKeeping_run_location_entry">
								<span>{ props.location.totals?.subscriptions.count }</span>
								<span>&nbsp;</span>
								<span>&nbsp;</span>
								<span>{ displayStripeAmount(props.location.totals?.subscriptions.amount) }</span>
							</div>
							)}
					</div>

				</div>

			</div>
		)
	)

}

