// import { makeRandomHash } from '../functions/util';
// 
// let _order = {
// 	deliveryId: '',
// 	subscription: {},
// 	deliveryItems: [],
// 	collectLocation: {},
// 	deliverLocation: {},
// 	coupon: {},
// };

// let order = _order;

let savedOrders = {}

export function saveOrder(order) {
	savedOrders[order.userId] = order;
}

export function retreiveOrder(fromThisUser) {
	return savedOrders[fromThisUser];
}
// 
// export function setSubscription(subscription) {
// 	order.subscription = subscription;
// }
// 
// export function setCoupon(coupon) {
// 	order.coupon = coupon;
// }
// 
// export async function addOrderItems(itemCodes) {
// 	order.deliveryItems = [...itemCodes];
// }
// 
// export async function setDeliveryId() {
// 	Object.assign(order, {
// 		deliveryId: makeRandomHash().toUpperCase()
// 	})
// 	return order.deliveryId;
// }
// 
// export async function setCollectLocation(collectLocation) {
// 	order.collectLocation = collectLocation;
// }
// 
// export async function setDeliverLocation(address) {
// 	Object.assign(order.deliverLocation, address);
// }
// 
// export async function getOrder() {
// 	return order;
// }