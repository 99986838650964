import React, { useState, useEffect, useRef } from 'react';

export default function TextInput(props) {
	let [isLoaded, setIsLoaded] = useState(false)
	let [value, setValue] = useState('');
	let [isFocused, setIsFocused] = useState(false);
	let [isSet, setIsSet] = useState(false);
	let _input = useRef();

	let focus = () => {
		_input.current.focus();
	}

	let onFocus = () => {
		setIsSet(true);
		setIsFocused(true);
	}

	let keyDown = (keyCode) => {
		if (keyCode === 13 && !!props.onEnter) {
			props.onEnter();
		}
	}

	let onBlur = () => {
		setIsFocused(false);
		if (!props.value) {
			setIsSet(false);
		}
	}

	let valueChange = (val) => {
		props.change(val)
	}

	useEffect(() => {
		setIsSet(isFocused || !!props.value)
		setTimeout(() => {
			setIsLoaded(true);
		}, 200)
	}, [props.value])

	return (
		<div className={`textInput ${isSet ? 'isSet' : ''} ${isLoaded ? 'isLoaded' : ''} ${props.className}`}>
			<input 
				type={ props.type || 'text'}
				value={ props.value } 
			  onChange={ e => valueChange(e.target.value) }
			  onFocus={ onFocus } 
			  onBlur={ onBlur }
			  ref={ _input }
			  onKeyDown={e => keyDown(e.keyCode)}
		  />
		  <span onClick={ focus }>{ props.label }</span>
		</div>
	)
}