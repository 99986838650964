import React, { useState, useEffect } from 'react';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function Touchable(props) {
	let [touched, setTouched] = useState(false);
	let [loading, setLoading] = useState(false);
	let [visible, setVisible] = useState(true);
	let [isInactive, setIsInactive] = useState(false);
	let [isSuccessful, setIsSuccessful] = useState(false);

	console.log

	let down = () => {
		setTouched(true);
	}

	let up = () => {
		setTouched(false);

		if (!isInactive) {
			props.onPress();
		}
	}

	useEffect(() => {
		setIsInactive(props.inactive);
	}, [props.inactive]);

	useEffect(() => {
		setLoading(props.loading);
	}, [props.loading]);

	useEffect(() => {

		console.log('GREAT SUCCESS', props.success)
		setIsSuccessful(props.success);

		if (props.success) {
			setTimeout(() => {
				setIsSuccessful(false);
			}, 2500)
		}
	}, [props.success]);

	return (
		<div 
			className={`touchable ${isSuccessful ? 'success' : ''} ${loading ? 'loading' : ''} ${touched ? 'touched' : ''} ${isInactive ? 'inactive' : ''} ${props.className}`}
			onMouseDown={ down }
			onMouseUp={ up }
		>

			{ isSuccessful && (
				<div className="touchableSuccess">
				  <CheckCircleIcon />
				</div>
			)}

			{ props.loading && (
				<div className="touchableLoader">
				  <AutorenewIcon />
				</div>
			)}

  		<div className="touchableInterior">
	  		{ props.children }
  		</div>

				
		</div>
	)

}