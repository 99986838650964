import React, { useState, useEffect, useRef } from 'react';

import BookKeepingLocation from './bookKeepingLocation';
import Opener from '../../&c./opener/opener';

import { 
	convertTimestampToHumanReadable_hourMinute,
} from '../../../functions/util';


export default function BookKeepingRun(props) {
	let [isLoading, setIsLoading] = useState(true);
	let [runOpen, setRunOpen] = useState(false);

	let toggleRunOpen = () => {
		setRunOpen(!runOpen)
	}

	useEffect(() => {
		console.log('RUN', props.index, props.run)
		if (!!props.run) {
			setIsLoading(false)
		}
	}, [props.run])

	return (
		!isLoading && (			
			<div key={ props.index } className={`bookKeeping_run ${runOpen ? 'run_open' : '' }`}>

				<div className="bookKeeping_run_head" >
					<Opener state={ runOpen } onPress={ toggleRunOpen } />
					<h2>{ convertTimestampToHumanReadable_hourMinute(props.index) }</h2>								
				</div>							

				<div className={ `bookKeeping_run_locations` }>
					{
						Object.keys(props.run).map((location, index) => (
							<BookKeepingLocation run={ props.index } index={ location } location={ props.run[location] } />							
						))								
					}
				</div>
			</div>	
		)
	)
}