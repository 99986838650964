import { promises } from 'semver-parser';
const { compareSemVer, isValidSemVer, parseSemVer } = promises;

import { api_getRemoteConfig } from '../functions/api';

import { setProducts } from './ProductsContext';

let _remoteConfig = {};

export async function remoteConfigSetup() {	
	_remoteConfig = await api_getRemoteConfig();

	console.log('_remoteConfig', _remoteConfig)

	setProducts(_remoteConfig.products);

	let compareSemver = await compareSemVer(_remoteConfig.minimumVersionAdmin, __APP_VERSION__);

	console.log('SEMVER', compareSemver)

	if (compareSemver === 1) {
		location.reload()
	}
}

export async function getRemoteConfig() {
	return _remoteConfig;
}