import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import BookKeepingRun from './bookKeepingRun'
import Touchable from '../../&c./touchable/touchable';
import Loader from '../../&c./loader/loader';

import { 
	api_doBookkeeping, 
	api_getBookkeeping 
} from '../../../functions/api';

import { 
	convertTimestampToHumanReadable,
	convertTimestampToHumanReadable_hourMinute,
	displayStripeAmount,
	isEmptyObject
} from '../../../functions/util';

import TextStrings from '../../../constants/TextStrings'

export default function BookKeeping() {
	let [isLoading, setIsLoading] = useState(true);
	let [bookKeeping, setBookKeeping] = useState({});
	let [doingTheBookKeeping, setDoingTheBookKeeping] = useState(false);
	let [didTheBookKeeping, setDidTheBookKeeping] = useState(false);

	let [bookKeepingOrder, setBookKeepingOrder] = useState([]);

	let hello = async () => {
		setIsLoading(true);
		let _theBookkeeping = await api_getBookkeeping();

		setBookKeeping(_theBookkeeping);

		console.log('_theBookkeeping', _theBookkeeping)

		if (!!_theBookkeeping) {
			let _bookKeepingOrder = Object.keys(_theBookkeeping).sort((a, b) => b - a);
			setBookKeepingOrder(_bookKeepingOrder)			
		}


		setIsLoading(false)
	}

	let doBookKeeping = async () => {
		setDoingTheBookKeeping(true);
		let _theBookkeeping = await api_doBookkeeping();

		setBookKeeping(_theBookkeeping);

		let _bookKeepingOrder = Object.keys(_theBookkeeping).sort((a, b) => b - a);
		setBookKeepingOrder(_bookKeepingOrder)

		setDoingTheBookKeeping(false);
		setDidTheBookKeeping(true);
		setDidTheBookKeeping(false);
	}	

	useEffect(() => {
		hello()
	}, [])

	return (
		<div className="bookKeeping">
			{ isLoading ? (
				<Loader />
			) : (
				<>
					
					<div className="bookKeepingHead">
						<h1>
							{ TextStrings.bookKeeping.title }
						</h1>

						<Touchable
							onPress={ doBookKeeping }
							loading={ doingTheBookKeeping }
							success={ didTheBookKeeping }
							>
							<p>{ TextStrings.bookKeeping.doBookKeeping }</p>
						</Touchable>

					</div>

					<div className="bookKeepingBody">
						{
							bookKeepingOrder.map((run, index) => (
								<BookKeepingRun index={ run } run={ bookKeeping[run] } />
							))
						}
					</div>
				</>
				
		)}
		</div>



	)
}