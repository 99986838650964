let cacheComponents = [
	'operatorEmail',
	'operatorPassword',
	'operatorUID',
	'locationId'
];

let cache = (() => {
	let theStorage = {};

	cacheComponents.forEach((component) => {

		theStorage[component] = {
			store: (value) => {
				localStorage.setItem(component, value.toString())
				console.log('STORED IN CACHE', component, value)
			},
			retreive: () => {
				let requested = localStorage.getItem(component);
				console.log('RETREIVED FROM CACHE', component, requested)
				return requested;
			},
			remove: () => {
				localStorage.removeItem(component);
				console.log('REMOVED FROM CACHE', component)
			}
		}
	})

	return theStorage;
})();

export default cache;
