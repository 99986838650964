import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import Touchable from '../../&c./touchable/touchable';
import TextInput from '../../&c./textInput/textInput';
import Head from '../../&c./head/head';
import Loader from '../../&c./loader/loader';

import { 
	api_getUser,
	api_updateUser,
	api_createUser,
} from '../../../functions/api';
import { setTitle } from '../../../functions/title';
import { navigate } from '../../../functions/navigator';

import { setUser, removeUser } from '../../../context/UserContext';
import { retreiveOrder } from '../../../context/OrderContext';
import { holdThatThought } from '../../../context/SearchResultsContext';
import { getServiceArea } from '../../../context/ServiceAreaContext'
import { theCurrentUserIsA } from '../../../context/LoggedInContext';

import TextStrings from '../../../constants/TextStrings'

import { 
	isEmptyObject, 
  makeRandomHash,
  convertTimestampToHumanReadable
} from '../../../functions/util';

export default function User() {
  let _nav = useNavigate();
	let { userId } = useParams();

	let [userMode, setUserMode] = useState();

	let [theUser, setTheUser] = useState({})

	let [firstName, setFirstName] = useState('');
	let [lastName, setLastName] = useState('');
	let [email, setEmail] = useState('');
	let [companyName, setCompanyName] = useState('');
	let [mobile, setMobile] = useState('');
	let [line1, setLine1] = useState('');
	let [line2, setLine2] = useState('');
	let [city, setCity] = useState('');
	let [zip, setZip] = useState('');

	let [floor, setFloor] = useState('');
	let [hasLift, setHasLift] = useState('');
	let [hasParking, setHasParking] = useState('');

	let [deliveryInstructions, setDeliveryInstructions] = useState('');

	let [isSubscribed, setIsSubscribed] = useState(false);

	let [userSaving, setUserSaving] = useState(false);
	let [userSaved, setUserSaved] = useState(false);

	let [userOrders, setUserOrders] = useState([]);

	let [incompleteOrder, setIncompleteOrder] = useState({});

	let [userCanBeSaved, setUserCanBeSaved] = useState(false);
	let [userLoaded, setUserLoaded] = useState(false);

	let [serviceArea, setServiceArea] = useState([]);

  let [role, setRole] = useState()

	let reset = () => {
		if (import.meta.env.VITE_ENVIRONMENT === 'dev') {
			setFirstName('test')
			setLastName('test')
			setLine1('Gerberngasse 43')
			setCity('Bern')
			setZip('3011')

			setFloor('1')
			setHasLift(false)
			setHasParking(true)

		} else {
			setFirstName('')
			setLastName('')
			setLine1('')
			setCity('')
			setZip('')

			setFloor('')
			setHasLift('')
			setHasParking('')
		}

		setEmail('')
		setCompanyName('')
		setMobile('')
		setLine2('')

		setDeliveryInstructions('')
		setIncompleteOrder([])

		setTheUser({})

		setIsSubscribed(false)
	}

	let hello = async () => {
		removeUser();

		let _userRole = await theCurrentUserIsA();
		setRole(_userRole)

		let _serviceArea = await getServiceArea();
		setServiceArea(_serviceArea);

		if (userId === 'new') {
			console.log('NEW USER')
			setUserMode('new');
			setTitle(TextStrings.user.newUser)
			reset();


		} else {
			setUserLoaded(false)
			let thisUser = await api_getUser(userId);
			console.log(thisUser)
			setTitle(TextStrings.user.existingUser + thisUser.uid)

			!isEmptyObject(thisUser.subscription) && setIsSubscribed(true)

			!!thisUser.email && setEmail(thisUser.email)
			!!thisUser.address.firstName && setFirstName(thisUser.address.firstName)
			!!thisUser.address.lastName && setLastName(thisUser.address.lastName)
			!!thisUser.address.companyName && setCompanyName(thisUser.address.companyName)
			!!thisUser.address.mobile && setMobile(thisUser.address.mobile)
			!!thisUser.address.line1 && setLine1(thisUser.address.line1)
			!!thisUser.address.line2 && setLine2(thisUser.address.line2)
			!!thisUser.address.city && setCity(thisUser.address.city)
			!!thisUser.address.zip && setZip(thisUser.address.zip)

			!!thisUser.address.floor && setFloor(thisUser.address.floor)
			thisUser.address.hasOwnProperty('hasLift') && setHasLift(thisUser.address.hasLift)
			thisUser.address.hasOwnProperty('hasParking') && setHasParking(thisUser.address.hasParking)
			!!thisUser.address.deliveryInstructions && setDeliveryInstructions(thisUser.address.deliveryInstructions)

			if (thisUser.hasOwnProperty('orders')) {
				let _orders = thisUser.orders;
				_orders.sort((a, b) => b.orderTimestamp - a.orderTimestamp)
				console.log(_orders)
				setUserOrders(_orders)
			}

			let theIncompleteOrder = retreiveOrder(userId);
			setIncompleteOrder(theIncompleteOrder)

			setTheUser(thisUser)
			setUserMode(userId);
		}

		setUserLoaded(true);
	}

	useEffect(() => {
		hello()
	}, [])

	useEffect(() => {
		console.log('ORDER ID', userId)
		hello()
	}, [userId])

	useEffect(() => {
		if (
			firstName !== '' && 
			lastName !== '' && 
			line1 !== '' && 
			zip !== '' && 
			city !== '' && 
			floor !== '' && 
			hasLift !== '' && 
			hasParking !== ''
		) {
			setUserCanBeSaved(true);
		} else {
			setUserCanBeSaved(false);
		}

	}, [
		firstName,
		lastName,
		line1,
		zip,
		city,
		floor,
		hasLift,
		hasParking
	])

	let saveUser = async () => {
		if (serviceArea.indexOf(zip) < 0) {
			alert(TextStrings.user.outOfServiceAreaError);
			return;
		}

		setUserSaving(true)

		let _user = {
			email,
	  	address: {
	  		city,
	  		companyName,
	  		deliveryInstructions,
	  		firstName,
	  		lastName,
	  		line1,
	  		line2,
	  		mobile,
	  		zip,
	  		floor,
	  		hasLift,
	  		hasParking
	  	},
		}

		if (userMode === 'new') {
			let newUser = await api_createUser(_user);
			console.log(newUser)

			navigate(_nav, `/home/user/${newUser}`)
			setUserMode(newUser);
		} else {
			console.log('USERID', userId)
			Object.assign(_user, {
				uid: userId
			})
			let updatedUser = await api_updateUser(_user);
		}

		setUserSaving(false);
		setUserSaved(!userSaved);
		setUser(_user);
	}

	let newOrder = () => {
		setUser(theUser);
		navigate(_nav, '/home/order/new')
	}

	let backButton = () => {
		holdThatThought()
		console.log('GO BACK THX')
	}

	return (
		userLoaded ? (
			<>
				<Head 
					_nav={ _nav }
					beforeBack={ backButton } 
					title={ userMode === 'new' ? TextStrings.user.newUser : `${TextStrings.user.existingUser}${firstName} ${lastName}`} />
				<div className={`user`}>
					<div className={`userDetail ${userMode === 'new' ? 'new' : ''}`}>

						<TextInput 
							className="userDetailInput"
							value={ firstName } 
							change={ setFirstName }
							label={ TextStrings.user.firstName }
						/>
					
						<TextInput 
							className="userDetailInput"
							value={ lastName } 
						  change={ setLastName }
						  label={ TextStrings.user.lastName }
					  />
					
						<TextInput 
							className="userDetailInput"
							type="email"
							value={ email } 
						  change={ setEmail }
						  label={ TextStrings.user.email }
					  />

						<TextInput 
							className="userDetailInput"
							value={ companyName } 
						  change={ setCompanyName }
						  label={ TextStrings.user.companyName }
					  />
					
						<TextInput 
							className="userDetailInput"
							type="tel"
							value={ mobile } 
						  change={ setMobile }
						  label={ TextStrings.user.mobile }
					  />
					
						<TextInput 
							className="userDetailInput"
							value={ line1 } 
						  change={ setLine1 }
						  label={ TextStrings.user.line1 }
					  />
					
						<TextInput 
							className="userDetailInput"
							value={ line2 } 
						  change={ setLine2 }
						  label={ TextStrings.user.line2 }
					  />
					
						<TextInput 
							className="userDetailInput"
							type="tel"
							value={ zip } 
						  change={ setZip }
						  label={ TextStrings.user.zip }
					  />

						<TextInput 
							className="userDetailInput"
							value={ city } 
						  change={ setCity }
						  label={ TextStrings.user.city }
					  />
					
						<TextInput 
							className="userDetailInput"
							type="tel"
							value={ floor } 
						  change={ setFloor }
						  label={ TextStrings.user.floor }
					  />
					
						<TextInput 
							className="userDetailInput full"
							value={ deliveryInstructions } 
						  change={ setDeliveryInstructions }
						  label={ TextStrings.user.deliveryInstructions }
					  />
						
						<div className="userDetailInput">			
							<div className="radio">
								<div 
									className={`option`}
									onClick={() => setHasLift(true)}
									>
									{ hasLift === true ? (
										<RadioButtonCheckedIcon />
									) : (
										<RadioButtonUncheckedIcon />
									)}
									<span>{ TextStrings.general.yes }</span>
								</div>
								<div 
									className={`option`}
									onClick={() => setHasLift(false)}
									>
									{ hasLift === false ? (
										<RadioButtonCheckedIcon />
									) : (
										<RadioButtonUncheckedIcon />
									)}
									<span>{ TextStrings.general.no }</span>
								</div>
							</div>
						  <span>{ TextStrings.user.hasLift }</span>
						</div>


						<div className="userDetailInput">					
							<div className="radio">
								<div 
									className={`option`}
									onClick={() => setHasParking(true)}
									>
									{ hasParking === true ? (
										<RadioButtonCheckedIcon />
									) : (
										<RadioButtonUncheckedIcon />
									)}
									<span>{ TextStrings.general.yes }</span>
								</div>
								<div 
									className={`option`}
									onClick={() => setHasParking(false)}
									>
									{ hasParking === false ? (
										<RadioButtonCheckedIcon />
									) : (
										<RadioButtonUncheckedIcon />
									)}
									<span>{ TextStrings.general.no }</span>
								</div>
							</div>
						  <span>{ TextStrings.user.hasParking }</span>
						</div>

						<Touchable
							className="userSave"
							onPress={ saveUser }
							inactive={ !userCanBeSaved }
							loading={ userSaving }
							success={ userSaved }
						>
							<SaveAltIcon />
							<p>{ TextStrings.user.save }</p>
						</Touchable>
					</div>

					<div className="userInteractions">
						<h3>{ TextStrings.subscription.subscription }</h3>
						{ isSubscribed ? (
							<>
								<p>{ TextStrings.subscription[theUser.subscription.period].period}</p>
								<p>
									{ convertTimestampToHumanReadable(theUser.subscription.subscriptionBeginTimestamp) } – 
									{ convertTimestampToHumanReadable(theUser.subscription.subscriptionEndTimestamp) }
								</p>
							</>
						) : (
							<p>{ TextStrings.user.noSubscription }</p>
						)}

						<h3>{ TextStrings.user.orders }</h3>

						{ role !== 'master' && (
							<Touchable
								className="newOrder"
								onPress={ newOrder }
								inactive={ (!userLoaded || userMode === 'new') }
							>
								<AddShoppingCartIcon />
								<p>{ TextStrings.user.newOrder }</p>
							</Touchable>
						)}
						
						<div className="userOrdersList">
							{
								userOrders.length && userOrders.map((order, index) => {
									return (
										<div key={ order.id + index } className="userOrder" onClick={ () => navigate(_nav, `/home/order/${order.id}`) }>
											
											<p>{ convertTimestampToHumanReadable(order.orderTimestamp) } – { order.collectLocation.companyName }</p>	

										</div>
									)
								})
							}

							{ !isEmptyObject(incompleteOrder) && (
								<>
									<h3>{ TextStrings.user.incompleteOrder }</h3>
									<div className="userOrder" onClick={ () => navigate(_nav, `/home/order/return`) }>
										
										<p>{ incompleteOrder.deliveryId }</p>	

									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</>
		) : (
			<Loader />
		)
	)

}