import React, { useState, useEffect } from 'react';
import { useParams, Outlet, useNavigate } from 'react-router-dom';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { api_getUser } from '../../../../functions/api';
import TextStrings from '../../../../constants/TextStrings'
import { displayStripeAmount, isEmptyObject } from '../../../../functions/util';

export default function Order(props) {
	let [user, setUser] = useState({});
	let [chosenOption, setChosenOption] = useState({});

	useEffect(() => {
		if (!isEmptyObject(props.order)) {
			setChosenOption(props.order.subscription)
		}
	}, [])

	let chooseProduct = (sub) => {
		setChosenOption(sub)
		props.onChoose(sub)
	}

	return (
		<div className="subscriptions">
			<h3>{ TextStrings.subscription.subscriptions }</h3>
			{
				props.products.subscriptions.map((sub, index) => {
					return (
						<div 
							key={ sub.period } 
							onClick={ () => chooseProduct(sub) } 
							className={`subscription_option ${chosenOption.period === sub.period ? 'chosen' : ''}`} >

							{chosenOption.period === sub.period ? (
								<RadioButtonCheckedIcon />
							) : (
								<RadioButtonUncheckedIcon />
							)}
							
							<h4>{ `${TextStrings.subscription[sub.period].period}` }</h4>	
							<p >{ `${ displayStripeAmount(sub.amount) } / ${TextStrings.subscription[sub.period].singleton}` }</p>	
						</div>
					)
				})
			}

			<div 
				onClick={ () => chooseProduct({
					nothing: true
				}) } 
				className={`subscription_option ${isEmptyObject(chosenOption) ? 'chosen' : ''}`} >

				{isEmptyObject(chosenOption) ? (
					<RadioButtonCheckedIcon />
				) : (
					<RadioButtonUncheckedIcon />
				)}
				
				<p >{ `${ TextStrings.subscription.noSubscription }` }</p>	
			</div>
		</div>
	)
}