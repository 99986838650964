import React, { useState, useEffect, useRef } from 'react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function Opener(props) {
	let press = () => {
		props.onPress();
	}

	return (
		<div className={`opener`} onClick={ press }>
		{
			props.state ? (
					<KeyboardArrowDownIcon />
				) : (
					<KeyboardArrowRightIcon />
				)
			}
		</div>
	)
}