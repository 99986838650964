import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import DashboardIcon from '@mui/icons-material/Dashboard';
// import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HelpIcon from '@mui/icons-material/Help';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import MinimizeIcon from '@mui/icons-material/Minimize';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import Touchable from '../touchable/touchable';

import TextStrings from '../../../constants/TextStrings'

import { api_logout, api_sendHelpMessage } from '../../../functions/api';
import cache from '../../../functions/cache';
import { navigate } from '../../../functions/navigator';

import { theCurrentUserIsA } from '../../../context/LoggedInContext';

export default function Nav(props) {

  let [operatorEmail, setOperatorEmail] = useState('');
  let [operatorUID, setOperatorUID] = useState('');
  let [locationId, setLocationId] = useState('');

  let [isFullscreen, setIsFullscreen] = useState(false)
  let [navOpen, setNavOpen] = useState(false);
  let [showingColophon, setShowingColophon] = useState(false);
  let [showingHelp, setShowingHelp] = useState(false);
  let [helpMessage, setHelpMessage] = useState('');
  let [helpSent, setHelpSent] = useState(false);
  let [userRole, setUserRole] = useState();

  let hello = async () => {
    let _operatorEmail = cache.operatorEmail.retreive();
    let _operatorUID = cache.operatorUID.retreive()
    let _locationId = cache.locationId.retreive()

    setOperatorEmail(_operatorEmail);
    setOperatorUID(_operatorUID);
    setLocationId(_locationId);
  }

  useEffect(() => {
    hello();
  }, []);

  let _nav = useNavigate( );

  let openTheNav = () => {
    let isNavOpen = navOpen;
    setNavOpen(!isNavOpen)
  }

  let go = (where) => {
    navigate(_nav, where)
    setNavOpen(false);
  }

  let activateFullscreen = () => {
    if (!isFullscreen) { 
      document.documentElement.requestFullscreen()
      setIsFullscreen(true);
    } else {
      document.exitFullscreen()
      setIsFullscreen(false);
    }
  }

  let toggleColophon = () => {
    setShowingColophon(!showingColophon);
  }

  let toggleHelp = () => {
    setShowingHelp(!showingHelp);
  }

  let sendHelp = async () => {
    console.log(helpMessage)

    let sos = await api_sendHelpMessage({
      message: helpMessage,
      url: window.location.href,
      operatorEmail,
      operatorUID,
      locationId
    });

    setHelpSent(true)
  }

  let closeHelp = () => {
    setHelpMessage('');
    setShowingHelp(false);
    setHelpSent(false)
  }

  let logOut = async () => {
    let logTheUserOut = await api_logout();
    navigate(_nav, '/')
  }

  return (
    <>
      <div className={`nav ${navOpen ? 'navOpen' : ''}`}>

        <div className="navOpener" onClick={ openTheNav }>
          { navOpen ? (
            <CloseIcon/>
          ) : (   
            <MenuIcon />
          )}
        </div>


        <div className="navInterior">
          <div className="navInteriorInterior">
            
            <div className="navHead" onClick={ toggleColophon }></div>

            { props.role === 'master' && (

              <div className="navButton" onClick={ () => go('/home/bookkeeping') }>
                <AccountBalanceIcon />
                <span>{ TextStrings.nav.bookkeeping }</span>
              </div>
            )}

            { props.role === 'admin' && (
              <div className="navButton" onClick={ () => go('/home/dashboard') }>
                <DashboardIcon />
                <span>{ TextStrings.nav.dashboard }</span>
              </div>
            )}
            <div className="navButton" onClick={ () => go('/home/search') }>
              <SearchIcon />
              <span>{ TextStrings.nav.search }</span>
            </div>
            <div className="navButton" onClick={ () => go('/home/orders') }>
              <FormatListBulletedIcon />
              <span>{ TextStrings.nav.orders }</span>
            </div>
            { props.role !== 'master' && (
              <div className="navButton" onClick={ () => go('/home/user/new') }>
                <PersonAddIcon />
                <span>{ TextStrings.nav.newUser }</span>
              </div>
            )}

            <div className="navBottom" >            
              <div className="navButton" onClick={ () => activateFullscreen() }>
                { isFullscreen ? (
                    <FullscreenExitIcon />
                  ) : (
                    <FullscreenIcon />
                  )
                }
                <span>{ TextStrings.nav.fullScreen }</span>
              </div>
              <div className="navButton" onClick={ toggleHelp }>
                <HelpOutlineIcon />
                <span>{ TextStrings.nav.help }</span>
              </div>
              <span>{ operatorEmail }</span>
              <div className="navButton" onClick={ logOut }>
                <LogoutIcon />
                <span>{ TextStrings.nav.logOut }</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="navBG" onClick={ openTheNav }></div>

      { showingColophon && (
        <div className="colophon" onClick={ toggleColophon }>
          <div className="colophon_interior">
            <h3>DingDong Admin.</h3>
            <p>v{ __APP_VERSION__ }</p>
            <p>© { new Date().getFullYear() } Rikscha Vision AG.</p>
          </div>
        </div>
      )}

      {
        showingHelp && (
          <div className="help background">
            <div className="help_interior">

              { helpSent ? (
                <>
                  <div className="help_received">
                    <p>{ TextStrings.help.received }</p>
                  </div>

                  <Touchable 
                    className="help_action"
                    onPress={ closeHelp }>
                    <p>{ TextStrings.general.ok }</p>
                  </Touchable>
                </>
              ) : (
                <>
                  <div className="help_minimize" onClick={ toggleHelp }>
                    <MinimizeIcon />
                  </div>
                  <h3 className="help_prompt">{ TextStrings.help.prompt }</h3>
                  <p>{ TextStrings.help.telephone }</p>
                  <p>{ TextStrings.help.email }<a href={`mailto:${TextStrings.help.emailAddress}`}>{TextStrings.help.emailAddress}</a></p>
                  <p className="help_lede">{ TextStrings.help.lede }</p>
                  <textarea 
                    placeholder={ TextStrings.help.placeholder }
                    value={ helpMessage }
                    onChange={ (e) => setHelpMessage(e.target.value) }>  
                  </textarea>

                  <Touchable 
                    className="help_action"
                    onPress={ sendHelp }>
                    <p>{ TextStrings.help.send }</p>
                  </Touchable>
                </>
              )}


            </div>
          </div>
        )
      }

      { (!showingHelp && helpMessage.length > 0) && (
        <div className="help_FAB" onClick={ toggleHelp }>
          <HelpIcon fontSize="large" />
        </div>  
      )}
    </>
  )
}