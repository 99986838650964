let _user =  {
	uid: '',
	email: '',
	address: {},
	orders: [],
	subscription: [],
}

let user = {};

export async function removeUser() {
	user = {};
}

export async function getUser() {
	return user
}

export async function setUser(userData) {
	!!userData && Object.assign(user, userData);
	console.log('(SET) AND THE USER NOW LOOKS LIKE', user)
}

export async function setOnUser(where, what) {
	console.log('HELLO AND HEY', where, what)
	user[where] = what;
	console.log('(SET ON) AND THE USER NOW LOOKS LIKE', user)
}